import { GET_LIST_PROJECT } from '../constants/Projects';

export const initState = {
  list_project: undefined,
};

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_LIST_PROJECT:
      return {
        ...state,
        list_project: action.data,
      };
    default:
      return state;
  }
};

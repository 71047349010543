import React from 'react';
import {Link} from 'react-router-dom';
import {Badge, Grid, Menu, Tooltip} from 'antd';
import IntlMessage from '../util-components/IntlMessage';
import Icon from '../util-components/Icon';
import navigationConfig from 'configs/NavigationConfig';
import {connect, useSelector} from 'react-redux';
import {NAV_TYPE_SIDE, SIDE_NAV_LIGHT} from 'constants/ThemeConstant';
import utils from 'utils';
import Utils from 'utils';
import {onMobileNavToggle} from 'redux/actions/Theme';
import _ from "lodash"

const {SubMenu} = Menu;
const {useBreakpoint} = Grid;

const setLocale = (isLocaleOn, localeKey) =>
    isLocaleOn ? <IntlMessage id={localeKey}/> : localeKey.toString();

const setDefaultOpen = (key) => {
    let keyList = [];
    let keyString = '';
    if (key) {
        const arr = key.split('-');
        for (let index = 0; index < arr.length; index++) {
            const elm = arr[index];
            index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
            keyList.push(keyString);
        }
    }
    return keyList;
};

const SideNavContent = (props) => {
    const {
        sideNavTheme,
        routeInfo,
        hideGroupTitle,
        localization,
        onMobileNavToggle,
    } = props;
    const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
    const closeMobileNav = () => {
        if (isMobile) {
            onMobileNavToggle(false);
        }
    };

    const userMe = Utils.getUserInfo()
    const userMeRole = userMe?.role?.type


    const {evaluatingTasks, pendingTasks} = useSelector((state) => state.task);

    return (
        <Menu
            theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
            mode="inline"
            style={{height: '100%', borderRight: 0}}
            defaultSelectedKeys={[routeInfo?.key]}
            defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
            className={hideGroupTitle ? 'hide-group-title' : ''}
            selectedKeys={[routeInfo?.key]}
        >
            {navigationConfig.map((menu, i) =>
                menu.submenu.length > 0 ? (
                    <Menu.ItemGroup
                        key={`one-${i}`}
                        title={setLocale(localization, menu.title)}
                        className={menu.permission && !_.includes(menu.permission, userMeRole) ? 'd-none' : null}
                    >
                        {menu.submenu.map((subMenuFirst, i2) =>
                                subMenuFirst.submenu.length > 0 ? (
                                    <SubMenu
                                        icon={
                                            subMenuFirst.image ? (
                                                <img src={subMenuFirst?.image} alt=""/>
                                            ) : null
                                        }
                                        key={`two-${i2}`}
                                        title={setLocale(localization, subMenuFirst.title)}
                                        className={subMenuFirst.permission && !_.includes(subMenuFirst.permission, userMeRole) ? 'd-none' : null}


                                    >
                                        {subMenuFirst.submenu.map((subMenuSecond,i3) => (
                                            <Menu.Item
                                                key={`three-${i3}`}
                                                className={subMenuSecond.permission && !_.includes(subMenuSecond.permission, userMeRole) ? 'd-none' : null}

                                            >
                                                {subMenuSecond.icon ? (
                                                    <Icon type={subMenuSecond?.icon}/>
                                                ) : null}
                                                <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                                                <Link
                                                    onClick={() => closeMobileNav()}
                                                    to={subMenuSecond.path}
                                                />
                                            </Menu.Item>
                                        ))}
                                    </SubMenu>
                                ) : (
                                    <Menu.Item
                                        key={subMenuFirst.key}
                                        className={subMenuFirst.permission && !_.includes(subMenuFirst.permission, userMeRole) ? 'd-none' : null}
                                    >
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                {subMenuFirst.icon ? <Icon type={subMenuFirst.icon}/> : null}
                                                <span>{setLocale(localization, subMenuFirst.title)}</span>
                                            </div>
                                            {
                                                subMenuFirst.key === 'work-list'
                                                    ?
                                                    (pendingTasks > 0
                                                            ?
                                                            (
                                                                <Tooltip
                                                                    title="Công việc cần xác nhận"
                                                                    overlayClassName="tooltip"
                                                                >
                                                                    <Badge count={pendingTasks}/>
                                                                </Tooltip>
                                                            )
                                                            :
                                                            (
                                                                <Tooltip
                                                                    title="Công việc cần xác nhận"
                                                                    overlayClassName="tooltip"
                                                                >
                                                                    <Badge count={pendingTasks}/>
                                                                </Tooltip>
                                                            )
                                                    )
                                                    :
                                                    (subMenuFirst.key === 'work-evaluating')
                                                        ?
                                                        (evaluatingTasks > 0 ? <Badge count={evaluatingTasks}/> : null)
                                                        : null
                                            }
                                        </div>
                                        <Link
                                            onClick={() => closeMobileNav()}
                                            to={subMenuFirst.path}
                                        />
                                    </Menu.Item>
                                ),
                        )}
                    </Menu.ItemGroup>
                ) : (
                    <Menu.Item
                        className={menu.permission && !_.includes(menu.permission, userMeRole) ? 'd-none' : null}
                        key={menu.key}
                    >
                        {menu.icon ? <Icon type={menu?.icon}/> : null}
                        <span>{setLocale(localization, menu?.title)}</span>
                        {menu.path ? (
                            <Link onClick={() => closeMobileNav()} to={menu.path}/>
                        ) : null}
                    </Menu.Item>
                ),
            )}
        </Menu>
    );
};

const TopNavContent = (props) => {
    const {topNavColor, localization} = props;
    return (
        <Menu mode="horizontal" style={{backgroundColor: topNavColor}}>
            {navigationConfig.map((menu,i) =>
                menu.submenu.length > 0 ? (
                    <SubMenu
                        key={`one-${i}`}
                        popupClassName="top-nav-menu"
                        title={
                            <span>
                {menu.icon ? <Icon type={menu?.icon}/> : null}
                                <span>{setLocale(localization, menu.title)}</span>
              </span>
                        }
                    >
                        {menu.submenu.map((subMenuFirst,i2) =>
                                subMenuFirst.submenu.length > 0 ? (
                                    <SubMenu
                                        key={`two-${i2}`}
                                        icon={
                                            subMenuFirst.icon ? (
                                                <Icon type={subMenuFirst?.icon}/>
                                            ) : null
                                        }
                                        title={setLocale(localization, subMenuFirst.title)}
                                    >
                                        {subMenuFirst.submenu.map((subMenuSecond,i3) => (
                                            <Menu.Item
                                                key={`three-${i3}`}

                                            >
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                                                <Link to={subMenuSecond.path}/>
                                            </Menu.Item>
                                        ))}
                                    </SubMenu>
                                ) : (
                                    <Menu.Item key={subMenuFirst.key}>
                                        {subMenuFirst.icon ? (
                                            <Icon type={subMenuFirst?.icon}/>
                                        ) : null}
                                        <span>{setLocale(localization, subMenuFirst.title)}</span>
                                        <Link to={subMenuFirst.path}/>
                                    </Menu.Item>
                                ),
                        )}
                    </SubMenu>
                ) : (
                    <Menu.Item key={menu.key}>
                        {menu.icon ? <Icon type={menu?.icon}/> : null}
                        <span>{setLocale(localization, menu?.title)}</span>
                        {menu.path ? <Link to={menu.path}/> : null}
                    </Menu.Item>
                ),
            )}
        </Menu>
    );
};

const MenuContent = (props) => {
    return props.type === NAV_TYPE_SIDE ? (
        <SideNavContent {...props} />
    ) : (
        <TopNavContent {...props} />
    );
};

const mapStateToProps = ({theme}) => {
    const {sideNavTheme, topNavColor} = theme;
    return {sideNavTheme, topNavColor};
};

export default connect(mapStateToProps, {onMobileNavToggle})(MenuContent);

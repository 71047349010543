import {DIR_LTR, NAV_TYPE_SIDE, SIDE_NAV_LIGHT} from 'constants/ThemeConstant';
import {env} from './EnvironmentConfig'

export const APP_NAME = 'HRM365';

export const KEY_APP_NAME = 'hrm';
export const API_BASE_URL = env.API_BASE_URL;
export const IS_TEAM = env.IS_TEAM;

export const API_BASE_GETWAY = IS_TEAM ? env.API_BASE_URL_GETWAY : API_BASE_URL;
export const TOKEN_APP_GETWAY = env.TOKEN_APP_GETWAY;
export const PUBLIC_URL = env.PUBLIC_URL;
export const API_ENDPOINT = env.API_ENDPOINT;
export const CLIENT_ID = env.CLIENT_ID;

export const INITIATE_LOGIN_ENDPOINT = env.INITIATE_LOGIN_ENDPOINT;
export const TEAM_PREFIX_PATH = '/tab';
export const APP_PREFIX_PATH = IS_TEAM ? `${TEAM_PREFIX_PATH}/app` : '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const PATTERN_PHONE = /(^(09|03|07|08|05)+([0-9]{8}$))|(^(84)+([0-9]{9}$))/;
export const PATTERN_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: IS_TEAM ? 'en-us' : 'vi-vn',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	isKanban: true
};

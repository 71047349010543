/** @format */

import * as actionType from '../constants/Company'

export const initState = {
    data: {
        status:undefined
    },
    hasCompany: false,
    currentStep: 0,
    isDisabledPrev: true,
    isDisabledNext: false,
    isDisabledDone: true,
    isCreatingTenant: false,
    status: 'normal',
    percent: 0,
    isFetching: false,
    error: false,
    message: "",
}

const CompanyReducer = (state = initState, action) => {
    let {type} = action
    switch (type) {
        case actionType.SETUP_COMPANY.REQUEST:
            return {
                ...state,
                isCreatingTenant: true,
                status: 'normal',
                percent: 0,
            }
        case actionType.CHECK_COMPANY.REQUEST:
            return {
                ...state,
                isFetching: true,
                message: null,
            }

        case actionType.CHECK_COMPANY.SUCCESS:
            return {
                ...state,
                error: false,
                message: null,
                data: action.payload,
                isFetching: false,

            }
        case actionType.SETUP_COMPANY.SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        case actionType.CHECK_COMPANY.FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true,
                status: 'exception',
                message: "Có lỗi trong quá trình kiểm tra tenant",
            }

        default:
            return state
    }
}

export default CompanyReducer

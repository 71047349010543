import React from 'react'

var colours = ['#75a6af', '#a47fa4', '#b2b26a', '#c99d88', '#F3B0C3', '#C6DBDA', '#FEE1E8', '#FED7C3', '#F6EAC2', '#ECD5E3', '#FF968A'];

function Avatar({user, styles}) {
    function generateColour(name) {
        // this is NOT a great hash but serves as an example
        var sum = 0;
        if (name) {
            for (var i = 0; i < name.length; i++) {
                sum++;
            }
            return sum % colours.length;
        }
    }

    if (user?.avatar) {
        return <img style={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            ...styles,
        }}
                    src={user.avatar}
                    alt={user.name}/>;
    } else {
        const firstLetter = user?.fullname ? user?.fullname.charAt(0).toUpperCase() : "";
        return (
            <div style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: colours[generateColour(user?.fullname)],
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '24px',
                color: '#fff',
                ...styles,
            }}>
                {firstLetter}
            </div>
        );
    }
}

export default Avatar;

import React, {useState} from "react";
import {Button, Tooltip} from "antd";
import {CheckCircleTwoTone, CloudSyncOutlined} from "@ant-design/icons";
import SyncService from "../../services/SyncService";
import {openNotification} from "../../utils/helpers";

const SynCom = () => {
    const [statusLoading, setStatusLoading] = useState(false);
    const [iconLoading, setIconLoading] = useState(<CloudSyncOutlined/>);
    const enterLoading = async () => {
        setStatusLoading(true);
        try {
            const sync = await SyncService.synManual();
            openNotification({
                type: sync?.success ? "success" : "error",
                des: sync?.message
            });
            setIconLoading(<CheckCircleTwoTone twoToneColor="#52c41a"/>);
            window.location.reload();
        } catch (err) {
            console.error("EX ", err);
            openNotification({
                type: "error",
                des: "An error occurred"
            });
        }
        setStatusLoading(false);
        setTimeout(() => setIconLoading(<CloudSyncOutlined/>), 2000);
    };
    return (
        <Tooltip
            placement="top" title={"Đồng bộ công việc"}>
            <Button
                type="ghost"
                icon={iconLoading}
                loading={statusLoading}
                onClick={() => enterLoading()}
            />
        </Tooltip>
    )
}

export default React.memo(SynCom)

import {all} from 'redux-saga/effects';
import Task from './Task';
import Auth from './Auth';
import Company from './Company';

export default function* rootSaga() {
  yield all([
    Auth(),
    Task(),
    Company(),
  ]);
}

import * as actionType from '../constants/Company';

const createActionSet = (type) => {
    return {
        request: (payload) => ({type: `${type.REQUEST}`, payload}),
        success: (payload) => ({type: `${type.SUCCESS}`, payload}),
        failure: (payload) => ({type: `${type.FAILURE}`, payload}),
    }
}


export const handleTenant = createActionSet(actionType.CHECK_COMPANY)
export const handleSetupCompany = createActionSet(actionType.SETUP_COMPANY)



import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {handleSetupCompany, handleTenant} from "../../redux/actions/company";
import {Progress, Space} from 'antd';
import {delay} from "../../utils/helpers";
import store from "../../redux/store";


const handleRunProgress = async (dispatch, getState, startPercent) => {
    let check = startPercent;
    let percent = startPercent;
    const max = 60; // Total number of increments to reach 100%
    const increment = 100 / max; // Increment percent by this amount each time

    let checkTimeElapsed = 0; // Time elapsed for check interval

    while (percent < 100) {
        const randomDelay = 500 + Math.random() * 2000; // Between 0.5 to 1.5 seconds
        await delay(randomDelay);
        percent = Math.round(Math.min(percent + increment, 100));
        const { status, percent: currentPercent } = getState();
        if (status === 'success' && currentPercent >= 100) {
            break;
        }

        if (percent > check) {
            check = percent;
            dispatch(handleSetupCompany.success({percent}));
        }
        checkTimeElapsed += 1;
        if (checkTimeElapsed >= 10) {
            checkTimeElapsed = 0;
            dispatch(handleTenant.request({initializing: true}));
        }
    }
};
const WinrarComplete = () => {
    const dispatch = useDispatch();

    const {status, percent, isCreatingTenant, data, message} = useSelector((state) => state.company);

    useEffect(() => {
        if (isCreatingTenant && percent < 100) {
            handleRunProgress(dispatch, () => store.getState().company, percent);
        } else if (!isCreatingTenant && !data?.id) {
            dispatch(handleSetupCompany.request())
        }
    }, [isCreatingTenant]);

    useEffect(() => {
        console.log("percent", percent)
    }, [percent]);

    return (
        <div>
            <Space wrap>
                <Progress type="circle" percent={percent} status={status}/>
            </Space>
            {message && <p>{message}</p>}
            <p>Video giới thiệu</p>
        </div>

    );
};

export default WinrarComplete;

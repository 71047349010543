import React from 'react';
import {Button, Card, Input, Space, Tag} from "antd";
import {useSelector} from "react-redux";
import {InfoCircleOutlined} from "@ant-design/icons";

const InactiveCompany = () => {
    const {data: company} = useSelector((state) => state.company);
    return (
        <Card
            className="text-center"
            title="Cần nâng cấp để tiếp tục sử dụng"
        >
            <div>
                <Space className="my-3">
                    <Tag icon={<InfoCircleOutlined/>} color="#cd201f">
                        Trial app has expired
                    </Tag>
                </Space>
            </div>
            <Space style={{ width: 500}} className="m-auto" direction="vertical">
                <Input addonBefore="Name" classNames="w-100" disabled defaultValue={company.tenantName} />
                <Input addonBefore="Code" disabled defaultValue={company.tenantCode} />
                <Input addonBefore="Phone" disabled defaultValue={company.phone} />
                {company.address &&
                    <Input addonBefore="Address" disabled defaultValue={company.address} />
                }
            </Space>
           <div>
               <div className="text-center mt-3">
                   <Button type="primary"> Upgrade Now</Button>
               </div>
           </div>
        </Card>
    );
};
export default InactiveCompany;

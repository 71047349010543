import fetch from 'auth/FetchInterceptor';

const ConfigService = {};

ConfigService.getListConfig = function () {
    return fetch({
        url: '/configs',
        method: 'get',
    });
}

ConfigService.getConfigByCode = function (code) {
    return fetch({
        url: `/configs/find-one/${code}`,
        method: 'GET',
        data:code
    })

}

ConfigService.createConfig = async function (values) {
    const response = await fetch({
        url: `/configs`,
        method: 'POST',
        data: values
    })

    return response;
}

ConfigService.updateConfig = async function (config) {
    const response = await fetch({
        url: `/configs/${config.id}`,
        method: 'PUT',
        data:config
    })

    return response;
}

ConfigService.deleteConfig = async function (id) {
    const response = await fetch({
        url: `/configs/${id}`,
        method: 'DELETE',
    })

    return response;
}

ConfigService.uploadFile = async function (values) {
    const response = await fetch({
        url: `/upload`,
        method: 'POST',
        data: values
    })

    return response;
}

export default ConfigService;
import fetch from 'auth/FetchInterceptor';

const StaffService = {};

StaffService.getListUser= function (params) {
    if(params){
        params['_sort'] = 'id:DESC';
    }
    return fetch({
        url: '/users',
        method: 'get',
        params,
    });
}
StaffService.getMe = function (params) {
    return fetch({
        url: '/users/me',
        method: 'get',
        params,
    });
}

StaffService.getList = function (params) {
    if(params){
        params['_sort'] = 'id:DESC';
    }
    return fetch({
        url: '/users/staffs',
        method: 'get',
        params,
    });
}

StaffService.getMyStaff = function (params) {
    if(params){
        params['_sort'] = 'id:DESC';
    }
    return fetch({
        url: '/users/my-staffs',
        method: 'get',
        params,
    });
}

StaffService.getUserByMsId = function (params) {
    return fetch({
        url: `/users/?msid=${params}`,
        method: 'get',
        params,
    });
}

StaffService.checkExistEmail = function (params) {
    return fetch({
        url: `/users/?email=${params}`,
        method: 'get',
        params,
    });
}

StaffService.getListStaffBySuperior = function (params) {
    if(params){
        params['_sort'] = 'id:DESC';
    }
    return fetch({
        url: '/users/staffs',
        method: 'get',
        params,
    });
}

StaffService.getListJobTitle = function (params = {}) {
    if(params){
        params['_sort'] = 'id:DESC';
    }
    return fetch({
        url: '/job-titles',
        method: 'get',
        params,
    });
}

StaffService.getListJobPositions = function (params) {
    return fetch({
        url: '/job-positions',
        method: 'get',
        params,
    });
}

StaffService.read = function (staff) {

    return  fetch({
        url: `/users/find-one/${staff.id}`,
        method: 'get',
    });
}

StaffService.create = async function (staff){

    const response = await fetch({
        url: `/users/create`,
        method: 'POST',
        data: staff
    })

    return response;
}

StaffService.update = async function (staff){

    const response = await fetch({
        url: `/users/update/${staff.id}`,
        method: 'PUT',
        data: staff
    })

    return response;
}

StaffService.delete = async function (id){

    const response = await fetch({
        url: `/users/delete/${id}`,
        method: 'DELETE',
    })

    return response;
}

StaffService.getCount = function (params) {

    return fetch({
        url: '/users/count',
        method: 'get',
        params,
    });
}

StaffService.getListDependent = function (params) {

    return fetch({
        url: '/dependents',
        method: 'get',
        params,
    });
}

StaffService.getListRelatives = function (params) {

    return fetch({
        url: '/relatives',
        method: 'get',
        params,
    });
}

StaffService.getListDepartments = function (params) {

    return fetch({
        url: '/departments',
        method: 'get',
        params,
    });
}

StaffService.getListIdentityPapers = function (params) {

    return fetch({
        url: '/Identity-papers',
        method: 'get',
        params,
    });
}

StaffService.getListRelationships = function (params) {

    return fetch({
        url: '/relationships',
        method: 'get',
        params,
    });
}

StaffService.export = function (params) {

    return fetch({
        url: '/users/export',
        method: 'get',
        params,
    });
};

StaffService.import = function (data) {

    return fetch({
        url: '/users/import',
        method: 'post',
        data,
    });
};



StaffService.getLicenses = function (params) {

    return fetch({
        url: 'cores/ms-teams/get-licenses',
        method: 'get',
        params,
    });
};

StaffService.getUserLicenseDetails = function (params) {

    return fetch({
        url: '/cores/ms-teams/getUserLicenseDetails',
        method: 'get',
        params,
    });
};
export default StaffService;
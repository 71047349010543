import {
    GET_PROJECTS_BY_TEAMS,
    GET_USERS_BY_TEAMS,
    GET_BUCKETS_BY_PROJECTS,
    GET_TEAMS_FOR_ME
} from '../constants/TaskDetail';

export const setTeamsForMe = (data) => {
    return {
        type: GET_TEAMS_FOR_ME,
        data,
    };
};

export const setProjectsByTeams = (data) => {
    return {
        type: GET_PROJECTS_BY_TEAMS,
        data,
    };
};

export const setUsersByTeams = (data) => {
    return {
        type: GET_USERS_BY_TEAMS,
        data,
    };
};

export const setBucketsByProject = (data) => {
    return {
        type: GET_BUCKETS_BY_PROJECTS,
        data,
    };
};

import * as actionType from '../constants/Tasks';
import {
  CARD_ACTIVE_TASK,
  FILTER_BOX,
  FILTER_TASK,
  GET_LIST_TASK,
  IS_ACTIVE_BOX,
  IS_KANBAN_TABLE,
  PROJECT_TASK,
  TAB_ACTIVE_TASK,
  WIDGET_TASK
} from '../constants/Tasks';

export const setListTask = (data) => {
  return {
    type: GET_LIST_TASK,
    data,
  };
};

export const setFilterTask = (data) => {
  return {
    type: FILTER_TASK,
    data,
  };
};

export const setTabActiveTask = (data) => {
  return {
    type: TAB_ACTIVE_TASK,
    data,
  };
};

export const setWidgetTask = (data) => {
  return {
    type: WIDGET_TASK,
    data,
  };
};

export const setProjectTask = (data) => {
  return {
    type: PROJECT_TASK,
    data,
  };
};

export const setCardActiveTask = (data) => {
  return {
    type: CARD_ACTIVE_TASK,
    data,
  };
};

export const setIsActiveBox = (data) => {
  return {
    type: IS_ACTIVE_BOX,
    data,
  };
};

export const setFiltersBox = (data) => {
  return {
    type: FILTER_BOX,
    data,
  };
};


export function onChangeIsKanban(isKanban) {
  return {
    type: IS_KANBAN_TABLE,
    isKanban
  };
}


const createActionSet = (type) => {
  return {
    request: (payload) => ({type: `${type.REQUEST}`, payload}),
    success: (payload) => ({type: `${type.SUCCESS}`, payload}),
    failure: (payload) => ({type: `${type.FAILURE}`, payload}),
  }
}


export const dispatchTaskCount = createActionSet(actionType.TASK_COUNT)



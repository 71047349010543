import fetch from 'auth/FetchInterceptor';
import {API_BASE_URL} from "../configs/AppConfig";

const LoginService = {}
LoginService.loginGetWay = function (data) {
    return fetch({
        url: `/api/cores/login-get-way`,
        baseURL: API_BASE_URL,
        method: 'post',
        headers: {
            authorization: false
        },
        data
    })
}

LoginService.handleCallbackLogin = function (urlCallback) {
    return fetch({
        url: urlCallback,
        method: 'get',
    })
}


LoginService.handleGetPermissions = function (id) {
    return fetch({
        url: `/users-permissions/roles/${id}`,
        method: 'get',
    })
}

LoginService.departmentsByManager = function (params, token = undefined) {
    return fetch({
        url: `/departments`,
        method: 'get',
        headers: {
            authorization: token
        },
        params
    })
}


export default LoginService

import axios from 'axios';
import {API_BASE_GETWAY, IS_TEAM} from 'configs/AppConfig';
import history from '../history';
import {AUTH_TOKEN} from 'redux/constants/Auth';
import {notification} from 'antd';
import qs from 'qs';
import {openNotification} from "../utils/helpers";
import store from "../redux/store";
import {resetAuth} from "../redux/actions/Auth";

const service = axios.create({
    baseURL: API_BASE_GETWAY,
    timeout: 60000,
    paramsSerializer: (params) => {
        return qs.stringify(params, {arrayFormat: 'repeat'});
    },
});
// Config
const ENTRY_ROUTE = '/auth/login';

// API Request interceptor
service.interceptors.request.use(
    async (config) => {
        let jwtToken = store.getState().auth.token;
        if (config.headers.authorization === false) {
            delete config.headers.authorization;
        } else if (jwtToken && !config.headers.authorization) {
            config.headers.authorization = `Bearer ${jwtToken}`;
        }
        return config;
    },
    (error) => {
        // Do something with request error here
        notification.error({
            message: 'Error',
        });
        Promise.reject(error);
    },
);

// API respone interceptor
service.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        let notificationParam = {
            message: '',
        };
        // Remove token and redirect
        if (error.response !== undefined && error.response) {

            if (error.response.status === 400) {
                if (error.response.data.message &&
                    error.response.data.message[0] &&
                    error.response.data.message[0].messages &&
                    error.response.data.message[0].messages[0] &&
                    error.response.data.message[0].messages[0].id === "Auth.form.error.blocked") {
                    notificationParam.message = error.response.data.message[0].messages[0].message || 'Please login again';
                } else {
                    notificationParam.message = 'Có lỗi xảy ra';
                    notificationParam.description = 'Please login again';
                }

            }
            if (error.response.status === 401) {
                notificationParam.message = 'Xác thực không thành công';
                notificationParam.description = 'Vui lòng đăng nhập lại';
                if (!IS_TEAM) {
                    localStorage.removeItem(AUTH_TOKEN);
                    history.push(ENTRY_ROUTE);
                    window.location.reload();
                }
                store.dispatch(resetAuth.request())
            }

            if (error.response.status === 403) {
                notificationParam.message = `Permission: ${error.response.data.message}`;
            }
            if (error.response.status === 428) {
                // yêu cầu điều kiện
                openNotification({
                    des: error.response.data.message,
                    type: "warning"
                })
                return Promise.reject();

            }

            if (error.response.status === 404) {
                notificationParam.message = 'Không tìm thấy trang';
            }

            if (error.response.status === 500) {
                notificationParam.message = 'Có lỗi xảy ra ở phía máy chủ';
            }

            if (error.response.status === 508) {
                notificationParam.message = 'Kết lối bị gián đoạn';
            }
            console.debug("openNotification FetchInterceptor")
            openNotification({
                title: notificationParam.message
            })
        }

        return Promise.reject(error);
    },
);

export default service;

import {all, fork, put, takeEvery} from 'redux-saga/effects';
import {TASK_COUNT} from "../constants/Tasks";
import WorkService from "../../services/WorkService";
import {dispatchTaskCount} from "../actions";
export function* handleTaskCount() {
    yield takeEvery(TASK_COUNT.REQUEST, function* () {

        try {
            const pendingTasks = yield WorkService.getTasksListCount({
                status: 'StaffPending',
            });
            const evaluatingTasks = yield WorkService.getTasksManagerCount({
                status: 'Completed',
                completeDate_null: 0,
            });
            yield put(dispatchTaskCount.success({pendingTasks,evaluatingTasks}));
        } catch (err) {
            console.log("handleTaskCount",err)
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(handleTaskCount),
    ]);
}

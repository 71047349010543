import React, {useEffect} from 'react';
import {handleSetupCompany} from "../../redux/actions/company";
import {useDispatch} from "react-redux";

const WinrarStepInfo = () => {
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(handleSetupCompany.success({ isDisabledPrev: true ,  isDisabledNext: false}));
    }, []);
    return (
        <div>video info about application</div>
    );
};

export default WinrarStepInfo;

import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Layout} from 'antd';
import {FileAddOutlined, MenuFoldOutlined, MenuUnfoldOutlined,} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';

import Logo from './Logo';
import NavProfile from './NavProfile';
import NavNotification from "./NavNotification";
import {onMobileNavToggle, toggleCollapsedNav} from 'redux/actions/Theme';
import {NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH} from 'constants/ThemeConstant';
import {APP_PREFIX_PATH, IS_TEAM} from "../../configs/AppConfig";

import utils from 'utils';
import SynCom from "../general/SyncCom";

const { Header } = Layout;

export const HeaderNav = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        navCollapsed,
        mobileNav,
        navType,
        headerNavColor,
        currentTheme,
    } = useSelector(state => state.theme);

    const isNavTop = navType === NAV_TYPE_TOP;
    const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as per your requirement
    const navMode = headerNavColor ? utils.getColorContrast(headerNavColor) : utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff');



    const onToggle = () => {
        if (!isMobile) {
            dispatch(toggleCollapsedNav(!navCollapsed));
        } else {
            dispatch(onMobileNavToggle(!mobileNav));
        }
    };

    const getNavWidth = () => {
        if (isNavTop || isMobile) return '0px';
        return `${navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH}px`;
    };

    return (
        <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
            <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''} justify-content-end`}>

                <Logo logoType={navMode}/>

                <div className="nav" style={{
                    width: `calc(100% - ${getNavWidth()})`,
                }}>
                    <div className="nav-left">
                        <ul className="ant-menu ant-menu-root d-flex m-0">
                            {!(isNavTop && !isMobile) && (
                                <>
                                    <li
                                        className="ant-menu-item ant-menu-item-only-child cursor-pointer"
                                        onClick={() => {
                                            onToggle();
                                        }}
                                    >
                                        {navCollapsed || isMobile ? (
                                            <MenuUnfoldOutlined className="nav-icon"/>
                                        ) : (
                                            <MenuFoldOutlined className="nav-icon"/>
                                        )}
                                    </li>
                                    <li
                                        className="ant-menu-item ant-menu-item-only-child"
                                    >
                                        <Button
                                            onClick={() => navigate(`${APP_PREFIX_PATH}/work/create-job`)}
                                            type="primary"
                                            className="ml-3"
                                        >
                                            <FileAddOutlined style={{fontSize: '15px'}}/> Thêm công việc
                                        </Button>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>

                    <div className="nav-right">

                        <div
                            style={{
                                alignSelf: IS_TEAM ? "self-end" : "center"
                            }}
                        >
                            <SynCom/>
                        </div>

                        {
                            !IS_TEAM && (
                                <>
                                    <div className="mr-3">
                                        <NavNotification/>
                                    </div>
                                    {/* <NavLanguage /> */}
                                    <NavProfile/>
                                    {/* <NavPanel direction={direction} /> */}
                                </>
                            )
                        }
                    </div>
                    {/* <NavSearch active={searchActive} close={onSearchClose} /> */}
                </div>
            </div>
        </Header>
    );
};

export default HeaderNav;

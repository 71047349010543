import { GET_LIST_DEPARTMENT } from '../constants/Departments';

export const initState = {
  list_department: undefined,
};

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_LIST_DEPARTMENT:
      return {
        ...state,
        list_department: action.data,
      };
    default:
      return state;
  }
};

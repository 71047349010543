const dev = {
    API_BASE_URL:  process.env.REACT_APP_BACKEND_URL || 'https://hrm.dev.dbiz.vn',
    API_BASE_URL_GETWAY:  process.env.REACT_APP_BACKEND_URL_GETWAY || 'https://hrm.dev.dbiz.vn',
    TOKEN_APP_GETWAY:  process.env.REACT_APP_TOKEN_GETWAY,
    INITIATE_LOGIN_ENDPOINT: process.env.REACT_APP_START_LOGIN_PAGE_URL,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    API_ENDPOINT: process.env.REACT_APP_FUNC_ENDPOINT,
    API_NAME: process.env.REACT_APP_FUNC_NAME,
    PUBLIC_URL: process.env.PUBLIC_URL,
    IS_TEAM: process.env.REACT_APP_PLATFORM === 'team',
};

// const prod = {
//     API_ENDPOINT_URL:  process.env.REACT_APP_BACKEND_URL || 'https://hrm.dev.dbiz.vn',
// };
//
// const test = {
//     API_ENDPOINT_URL:  process.env.REACT_APP_BACKEND_URL || 'https://hrm.dev.dbiz.vn',
// };

const getEnv = () => {
    return dev
    // switch (process.env.NODE_ENV) {
    //     case 'development':
    //         return dev;
    //     case 'production':
    //         return prod;
    //     case 'test':
    //         return test;
    //     default:
    //         break;
    // }
};

export const env = getEnv();

import {
    GET_PROJECTS_BY_TEAMS,
    GET_USERS_BY_TEAMS,
    GET_BUCKETS_BY_PROJECTS,
    GET_TEAMS_FOR_ME
} from '../constants/TaskDetail';

export const initState = {
    teams: [],
    projects: [],
    users: [],
    buckets: [],
};

export const reducer = (state = initState, action) => {
    switch (action.type) {
        case GET_TEAMS_FOR_ME:
            return {
                ...state,
                teams: action.data,
            };
        case GET_PROJECTS_BY_TEAMS:
            return {
                ...state,
                projects: action.data,
            };
        case GET_USERS_BY_TEAMS:
            return {
                ...state,
                users: action.data,
            };
        case GET_BUCKETS_BY_PROJECTS:
            return {
                ...state,
                buckets: action.data,
            };
        default:
            return state;
    }
};

import React, {useContext, useEffect} from "react";
import {useData} from "@microsoft/teamsfx-react";
import {app} from "@microsoft/teams-js";
import {TeamsFxContext} from "./Context";
import {handleLoginGetWay, signIn} from "../redux/actions/Auth";
import {useDispatch, useSelector} from "react-redux";
import {APP_PREFIX_PATH, TEAM_PREFIX_PATH} from "../configs/AppConfig";
import {useNavigate} from "react-router-dom";
import {Card, Col, Row, Space, Spin} from 'antd';
import WinrarCompany from "./winrar/WinrarCompany";
import IntlMessage from "../components/util-components/IntlMessage";
import StaffService from "../services/StaffService";
import _ from "lodash";
// eslint-disable-next-line react/display-name
const Welcome = React.memo((props) => {

    const {showFunction, environment} = {
        showFunction: true,
        environment: window.location.hostname === "localhost" ? "local" : "azure",
        ...props,
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const friendlyEnvironmentName =
        {
            local: "local environment",
            azure: "Azure environment",
        }[environment] || "local environment";

    const {teamsUserCredential} = useContext(TeamsFxContext);
    const {loading, error, token} = useSelector((state) => state.auth);
    const {hasCompany, data: {status}} = useSelector((state) => state.company);
    const {isStaff, ...userInfo} = useSelector((state) => state.auth.user_info);

    useEffect(() => {
        if (teamsUserCredential) {
            dispatch(handleLoginGetWay.request({renewToken: true, teamsUserCredential}))
        }
    }, [teamsUserCredential]);

    const userName = loading ? "" : userInfo.displayName;
    const hubName = useData(async () => {
        await app.initialize();
        const context = await app.getContext();
        return context.app.host.name;

    })?.data;

    const handleInitial = async () => {
        if (hasCompany && token) {

            if (isStaff) {
                navigate(APP_PREFIX_PATH);
                return;
            } else if (_.isBoolean(isStaff) && isStaff === false) {
                navigate(`${TEAM_PREFIX_PATH}/info-company`);
                return;
            }

            if (status === "inactive") {
                navigate(`${TEAM_PREFIX_PATH}/upgrade`);
                return;
            }

            if (!userInfo.id) {
                try {
                    const user = await StaffService.getMe();
                    dispatch(signIn(user))
                    return;
                }catch (e) {
                    console.log(e)
                    navigate(`${TEAM_PREFIX_PATH}/info-company`);
                }
            }
        }
    }

    useEffect(() => {
        handleInitial()
    }, [hasCompany, isStaff, status, token]);

    console.log({hasCompany, friendlyEnvironmentName, showFunction, hubName, environment})
    if (loading || hasCompany || !error || !token) {
        return (<Space
            direction="vertical"
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
                background: "#ffffff"
            }}
        >
            <Spin tip="Loading">
                <div className="content" style={{
                    padding: 50,
                    background: 'rgba(0, 0, 0, 0.05)',
                    borderRadius: 4
                }}/>
            </Spin>
        </Space>);
    }

    return (
        <Card title={<span><IntlMessage id="team.winrar.title"></IntlMessage> {userName}</span>}
              className="text-center"
              bordered={false}>
            <Row gutter={16}>
                <Col span={12} offset={6}>
                    <WinrarCompany/>
                </Col>
            </Row>
        </Card>
    );
});


export default Welcome;
import React from 'react';
import {Button, Dropdown, Flex, Space} from 'antd';
import {connect, useDispatch} from 'react-redux';
import {signOut, signOutSuccess} from 'redux/actions/Auth';
import Utils from "../../utils";
import Avatar from "../util-components/Avatar";

export const NavProfile = () => {
  const user_info = Utils.getUserInfo();
  const dispatch = useDispatch();
    const items = [
        {
            key: '1',
            label: (
                <div className="nav-profile-content">
                    <div className="d-flex align-items-center">
                        <Avatar user={user_info} />
                        <div className="pl-3">
                            <h4 className="mb-0 mt-0">{user_info ? (user_info?.fullname ? user_info.fullname : user_info.username) : ''}</h4>
                            <span className="text-muted">{user_info?.email}</span>

                        </div>

                    </div>
                    <Flex vertical className="mt-3" >
                        <Button type="link" danger onClick={()=> dispatch(signOutSuccess())}>
                            Đăng xuất
                        </Button>

                    </Flex>
                </div>
            ),
        },
    ];
    return (
        <Dropdown
            menu={{
                items,
            }}
        >
            <a onClick={(e) => e.preventDefault()}>
                <Space>
                    <Avatar user={user_info} styles={{
                        marginTop: '15px',
                        fontSize: '18px',
                        width: '36px',
                        height: '36px',
                    }} />
                </Space>
            </a>
        </Dropdown>
    );
};

export default connect(null, { signOut })(NavProfile);

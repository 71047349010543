import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = document.getElementById('root');
const reactRoot = createRoot(root);
reactRoot.render(<App />);

serviceWorkerRegistration.register();

import {applyMiddleware, compose, createStore} from 'redux';
import reducers from '../reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/index';
import persistState from 'redux-localstorage';
import {pick} from 'lodash';
import {initState as usersInitState} from '../reducers/Users';
import {initState as projectsInitState} from '../reducers/Projects';
import {initState as departmentsInitState} from '../reducers/Departments';
import {initState as statusInitState} from '../reducers/Status';
import {initState as scopeInitState} from '../reducers/Scope';
import {initState as configInitState} from '../reducers/SystemConfiguration';
import {initState as taskInitState} from '../reducers/Tasks';
import {initState as taskDetailInitState} from '../reducers/TaskDetail';
import {initState as authState} from '../reducers/Auth';
import {initTheme as themeState} from '../reducers/Theme';
import {initState as companyState} from '../reducers/CompanyReducer';
import {KEY_APP_NAME} from "../../configs/AppConfig";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const enhancers = [
    persistState(undefined, {
        key: KEY_APP_NAME,
        slicer: () => (state) => {
            // filter which substore to persist
            const subset = {
                configs: {
                    ...configInitState,
                    ...pick(state.configs, 'list_config'),
                },
                users: {
                    ...usersInitState,
                    ...pick(state.users, 'list_user'),
                },
                projects: {
                    ...projectsInitState,
                    ...pick(state.projects, 'list_project'),
                },
                departments: {
                    ...departmentsInitState,
                    ...pick(state.departments, 'list_department'),
                },
                status: {
                    ...statusInitState,
                    ...pick(state.status, 'list_status'),
                },
                scope: {
                    ...scopeInitState,
                    ...pick(state.scope, 'list_scope'),
                },
                task: {
                    ...taskInitState,
                    ...state.task
                },
                taskDetail: {
                    ...taskDetailInitState,
                    ...state.taskDetail,
                },
                auth: {
                    ...authState,
                    ...state.auth,
                },
                company: {
                    ...companyState,
                    ...state.company,
                },
                theme: {
                    ...themeState,
                    ...state.theme,
                },

            };
            return subset;
        },
    }),
];

function configureStore(preloadedState) {
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        reducers,
        preloadedState,
        composeEnhancers(applyMiddleware(...middlewares), ...enhancers),
    );
    sagaMiddleware.run(rootSaga);
    // eslint-disable-next-line no-undef
    if (module.hot) {
        // eslint-disable-next-line no-undef
        module.hot.accept('../reducers/index', () => {
            // eslint-disable-next-line no-undef
            const nextRootReducer = require('../reducers/index');
            store.replaceReducer(nextRootReducer);
        });
    }
    return store;
}

const store = configureStore();

export default store;

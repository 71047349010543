import enLang from './entries/en_US';
import viLang from './entries/vi_VN';

const AppLocale = {
    "en-us": enLang,
    "vi-vn": viLang,
    // zh: zhLang,
    // fr: frLang,
    // ja: jaLang
};

export default AppLocale;

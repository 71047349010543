import { GET_LIST_USER } from '../constants/Users';

export const initState = {
  list_user: undefined,
};

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_LIST_USER:
      return {
        ...state,
        list_user: action.data,
      };
    default:
      return state;
  }
};

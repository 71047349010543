import {all, fork, put, select, takeEvery} from 'redux-saga/effects';
import {CHECK_COMPANY, SETUP_COMPANY} from "../constants/Company";
import CompanyService from "../../services/CompanyService";
import {handleSetupCompany, handleTenant} from "../actions/company";
import {initState} from "../reducers/CompanyReducer";

export function* handleInit() {
    yield takeEvery(CHECK_COMPANY.REQUEST, function* ({payload}) {
        try {
            const companyInit = yield select(state => state.company.data);
            const company = yield CompanyService.myTenant();
            const data = {
                data: {...companyInit, ...company},
            };
            console.log("CHECK_COMPANY.REQUEST",{company})
            switch (company?.status) {
                case 'error':
                    Object.assign(data, {
                        status: "exception",
                        currentStep: 2,
                        isCreatingTenant: true,
                        isDisabledNext: true,
                        message: "Có lỗi trong quá trình khởi tạo ..."
                    });
                    break;
                case 'initializing':
                    Object.assign(data, {
                        status: "normal",
                        currentStep: 2,
                        isCreatingTenant: true,
                        isDisabledNext: true,
                        message: "Đang khởi tạo ..."
                    });
                    break;
                case 'inactive':
                case 'active':
                    if (payload?.initializing) {
                        Object.assign(data, {
                            status: "success",
                            percent: 100,
                            message: "Khởi tạo thành công bắt đầu sử dụng ứng dụng..."
                        });
                        yield put(handleSetupCompany.success(data));
                    }
                    data.hasCompany = true;
                    break;
                default:
                    Object.assign(data, {
                        ...initState,
                        status: "normal",
                        percent: 0,
                        currentStep: 0,
                        message: "Khởi tạo dụng ứng dụng...",
                        hasCompany:false,
                    });
                    break;
            }

            yield put(handleSetupCompany.success(data));
        } catch (err) {
            console.error("handleInit saga company", err)
            yield put(handleTenant.failure());
        }
    });
}


export function* handleCompleteSetup() {
    yield takeEvery(SETUP_COMPANY.REQUEST, function* () {
        const companyData = yield select(state => state.company.data);

        try {

            yield CompanyService.create(companyData);

        } catch (err) {
            console.error("handleCompleteSetup saga company", err)
            yield put(handleSetupCompany.failure());
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(handleInit),
        fork(handleCompleteSetup),
    ]);
}

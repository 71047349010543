export const HRM_ROLE_TAG =  process.env.REACT_APP_HRM_TAG || "hrm"
export const NGAY_NGHI_PHEP_CO_LUONG_TRONG_NAM = "NGAY_NGHI_PHEP_CO_LUONG_TRONG_NAM"
export const TEAM_ID_FOR_OPEN_SHIFT = "TEAMID_CHAM_CONG_MAC_DINH"
export const HE_SO_LAM_THEM_GIO_NGAY_THUONG = "HE_SO_LAM_THEM_GIO_NGAY_THUONG"
export const HE_SO_LAM_THEM_GIO_CUOI_TUAN = "HE_SO_LAM_THEM_GIO_CUOI_TUAN"
export const HE_SO_LAM_THEM_GIO_NGAY_LE = "HE_SO_LAM_THEM_GIO_NGAY_LE"
export const SO_NAM_THAM_NIEN_DUOC_CONG_THEM_NGAY_NGHI = "SO_NAM_THAM_NIEN_DUOC_CONG_THEM_NGAY_NGHI"
export const SO_THANG_AP_DUNG_CONG_DON_PHEP_CUA_NAM_TRUOC = "SO_THANG_AP_DUNG_CONG_DON_PHEP_CUA_NAM_TRUOC"
export const QUY_THOI_GIAN_MAC_DINH = "QUY_THOI_GIAN_MAC_DINH"

export const COLUMN_NAMES = {
  CANCEL: 'Canceled',
  STAFF_PENDING: 'StaffPending',
  MANAGER_PENDING: 'ManagerPending',
  NOT_START: 'NotStarted',
  IN_PROGRESS: 'InProgress',
  COMPLETED: 'Completed',
  // PASSED:'Passed',
}

import {
    AppstoreOutlined,
    CalculatorOutlined,
    CheckCircleOutlined,
    DashboardOutlined,
    FileProtectOutlined,
    HistoryOutlined,
    HomeOutlined,
    LineChartOutlined,
    MoneyCollectOutlined,
    ScheduleOutlined,
    SettingOutlined,
    TableOutlined,
    UserOutlined
} from '@ant-design/icons';
import {APP_PREFIX_PATH} from 'configs/AppConfig';
import {HRM_ROLE_TAG} from "../constants/SystemConfig";


const menuApp = [
    {
        key: 'dashboards-app',
        path: `${APP_PREFIX_PATH}/dashboards-app`,
        title: 'sidenav.dashboard',
        icon: DashboardOutlined,
        permission: "",
        image: '/img/others/menu-1.png',
        breadcrumb: false,
        submenu: [],
    },
    {
        key: 'work',
        path: `${APP_PREFIX_PATH}/work`,
        title: 'app.work',
        icon: AppstoreOutlined,
        breadcrumb: false,
        permission: "",
        submenu: [
            {
                key: 'work-list',
                path: `${APP_PREFIX_PATH}/work/list`,
                title: 'app.work.list',
                icon: AppstoreOutlined,
                breadcrumb: false,
                permission: "",
                submenu: [],
            },
            {
                key: 'work-evaluating',
                path: `${APP_PREFIX_PATH}/work/evaluating`,
                title: 'app.work.evaluating',
                icon: CheckCircleOutlined,
                breadcrumb: false,
                permission: "",

                submenu: [],
            },
            // {
            //     key: 'work-calendar',
            //     path: `${APP_PREFIX_PATH}/work/calendar`,
            //     title: 'app.work.calendar',
            //     icon: CalendarOutlined,
            //     breadcrumb: false,
            //     permission: "",
            //     submenu: [],
            // },
        ],
    },

    {
        key: 'salary',
        path: `${APP_PREFIX_PATH}/salary`,
        title: 'app.salary',
        icon: MoneyCollectOutlined,
        breadcrumb: false,
        permission: "",

        submenu: [
            {
                key: 'salary-structure',
                path: `${APP_PREFIX_PATH}/salary-structure`,
                title: 'app.salary-structure',
                icon: TableOutlined,
                breadcrumb: false,
                permission: "",
                submenu: [],
            },
            {
                key: 'paycheck',
                path: `${APP_PREFIX_PATH}/paycheck`,
                title: 'app.paycheck',
                icon: FileProtectOutlined,
                breadcrumb: false,
                permission: "",

                submenu: [],
            },
            {
                key: 'salary-summaries',
                path: `${APP_PREFIX_PATH}/salary-summaries/payroll`,
                title: 'app.salary_summaries',
                icon: MoneyCollectOutlined,
                breadcrumb: false,
                permission: "",

                submenu: [],
            },
        ],
    },

    {
        key: 'request',
        path: `${APP_PREFIX_PATH}/salary-summaries`,
        title: 'app.request',
        icon: SettingOutlined,
        breadcrumb: false,
        submenu: [
            // {
            //     key: 'timekeeping1',
            //     path: `${APP_PREFIX_PATH}/salary-summaries/timekeeping1`,
            //     title: 'app.timekeeping1',
            //     icon: ScheduleOutlined,
            //     breadcrumb: false,
            //
            //     submenu: [],
            // },
            {
                key: 'timekeeping',
                path: `${APP_PREFIX_PATH}/salary-summaries/timekeeping`,
                title: 'app.timekeeping',
                icon: ScheduleOutlined,
                breadcrumb: false,

                submenu: [],
            },
          /*  {
                key: 'overtime',
                path: `${APP_PREFIX_PATH}/salary-summaries/overtime`,
                title: 'app.overtime',
                icon: ClockCircleOutlined,
                breadcrumb: false,

                submenu: [],
            },
            {
                key: 'salary-advance',
                path: `${APP_PREFIX_PATH}/salary-summaries/salary-advance`,
                title: 'app.salary-advance',
                icon: MoneyCollectOutlined,
                breadcrumb: false,

                submenu: [],
            },*/
        ]
    },

    {
        key: 'report',
        path: `${APP_PREFIX_PATH}/report`,
        title: 'app.report',
        icon: LineChartOutlined,
        breadcrumb: false,
        submenu: [
            {
                key: 'kpi-spreadsheet',
                path: `${APP_PREFIX_PATH}/report/kpi-spreadsheet`,
                title: 'app.report.kpi_spreadsheet',
                icon: CalculatorOutlined,
                breadcrumb: false,
                permission: "",

                submenu: [],
            },
            // {
            //     key: 'kpi-spreadsheet-department',
            //     path: `${APP_PREFIX_PATH}/report/kpi-spreadsheet-department`,
            //     title: 'app.report.kpi_spreadsheet_department',
            //     icon: CalculatorOutlined,
            //     breadcrumb: false,
            //     permission: [HRM_ROLE_TAG],
            //
            //     submenu: [],
            // },
            {
                key: 'kpi-spreadsheet-room',
                path: `${APP_PREFIX_PATH}/report/kpi-spreadsheet-room`,
                title: 'app.report.kpi_spreadsheet_room',
                icon: CalculatorOutlined,
                breadcrumb: false,
                permission: [HRM_ROLE_TAG,"manager"],
                submenu: [],
            },
        ],
    },
    {
        key: 'settings',
        path: `${APP_PREFIX_PATH}/settings`,
        title: 'app.settings',
        icon: SettingOutlined,
        breadcrumb: false,
        permission: [HRM_ROLE_TAG],
        submenu: [
            {
                key: 'staff',
                path: `${APP_PREFIX_PATH}/hrm/staff`,
                title: 'app.staff',
                icon: UserOutlined,
                breadcrumb: false,
                permission: [HRM_ROLE_TAG],

                submenu: [],
            },
            {
                key: 'department',
                path: `${APP_PREFIX_PATH}/hrm/department`,
                title: 'app.department',
                icon: HomeOutlined,
                breadcrumb: false,
                permission: [HRM_ROLE_TAG],

                submenu: [],
            },
            {
                key: 'time-fund',
                path: `${APP_PREFIX_PATH}/hrm/time-fund`,
                title: 'app.settings.time-fund',
                icon: HistoryOutlined,
                breadcrumb: false,
                permission: [HRM_ROLE_TAG],

                submenu: [],
            },
            {
                key: 'config-salary',
                path: `${APP_PREFIX_PATH}/hrm/payroll`,
                // path: `${APP_PREFIX_PATH}/hrm/system-configs`,
                title: 'app.config-salary',
                icon: SettingOutlined,
                breadcrumb: false,
                permission: [HRM_ROLE_TAG],

                submenu: [],
            },
            // {
            //     key: 'global-config',
            //     path: `${APP_PREFIX_PATH}/settings/global-config`,
            //     title: 'app.settings.global-config',
            //     icon: SettingOutlined,
            //     breadcrumb: false,
            //     permission: [HRM_ROLE_TAG],
            //
            //     submenu: [],
            // },
            // {
            //   key: 'rules',
            //   path: `${APP_PREFIX_PATH}/settings/rules`,
            //   title: 'app.settings.rules',
            //   icon: PartitionOutlined,
            //   breadcrumb: false,
            //   submenu: [],
            // },
        ],
    },
];

const navigationConfig = [
    ...menuApp,
    // ...dashBoardNavTree,
    // ...appsNavTree,
    // ...componentsNavTree,
    // ...extraNavTree,
    // ...docsNavTree,
];

export default navigationConfig;

import React, {useRef, useState} from 'react';
import {Avatar, Badge, Dropdown, List, Menu, message, Tooltip, Tour} from 'antd';
import {
    BellOutlined,
    CheckCircleOutlined,
    CheckCircleTwoTone,
    DeleteOutlined,
    InfoCircleOutlined,
    MailOutlined,
    MoreOutlined,
    WarningOutlined
} from '@ant-design/icons';
import notificationData from "assets/data/notification.data.json";
import Flex from 'components/shared-components/Flex'
import Nofitication from "../../services/Notification";
import {USER_INFO} from "../../redux/constants/Auth";
import _ from 'lodash';
import dayjs from "dayjs";
import tourNotification from "../../assets/tour/notification.mp4";

const getIcon = icon => {
    switch (icon) {
        case 'mail':
            return <MailOutlined/>;
        case 'alert':
            return <WarningOutlined/>;
        case 'check':
            return <CheckCircleOutlined/>
        default:
            return <MailOutlined/>;
    }
}

export const NavNotification = () => {
    const user_info = JSON.parse(localStorage.getItem(USER_INFO));
    const initFilter = {
        userID: user_info?.id,
        _start: 0,
        _limit: 10
    };

    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(notificationData);
    const [filter, setFilter] = useState(initFilter);
    const [open, setOpen] = useState(false);
    const steps = [
        {
            title: 'Nhận thông báo trên thiết bị',
            description: 'Cấp quyền trình duyệt , cấp quyền ứng dụng',
            cover: (
                <video
                    autoPlay
                    controls
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                    src={tourNotification}
                />
            ),
            target: () => ref1.current,
        },
        {
            title: 'Thống báo tự động',
            description: 'Hệ thống tự động thông báo lên thiết bị mới khi đăng nhập trên thiết bị mới',
            target: () => ref1.current,
        },
        {
            title: 'Chỉ thông báo lên một thiết bị ',
            description: 'Hệ thống chỉ thống báo trên một thiết bị đăng nhập gần nhất -> người dùng có thể tắt thông báo theo bước 1',
            target: () => ref1.current,
        },
    ];
    const ref1 = useRef(null);

    const handleVisibleChange = (flag) => {
        setVisible(flag);
    }

    const getListNotification = async (data) => {
        const [notifications, countUnRead] = await Promise.all([
            Nofitication.getListNotification(data),
            Nofitication.countUnReadNotification({
                userID: user_info?.id, 
                is_read_null: true
            }),
        ]);
        const result = await Nofitication.getListNotification(data);
        if (result) {
            setData({notifications, countUnRead});
        }
    }

    const readNotification = async (item) => {
        if (!item?.is_read) {
            await Nofitication.updateNotification(item.id, {...item, is_read: true});
            await getListNotification(filter);
        }
        if (item?.url && item?.url !== window.location.host) {
            window.location.replace(item?.url)
        }
    }

    const LoadMore = () => {
        setFilter({
            ...filter,
            _limit: filter._limit + 5
        })
    }

    React.useEffect(() => {
        getListNotification(filter)
    }, [filter]);

    const handelDeleteNotify = async (id) => {
        try {
            const result = await Nofitication.deleteNotification(id)
            if (result?.id) {
                getListNotification(filter)
                return message.success('Xoá thông báo thành công', 1)
            }
            return message.error('Xoá thông báo thất bại', 1)
        } catch (e) {
            message.error('Xoá thông báo thất bại', 1)
        }
    }
    const deleteBulkNotification = async (params) => {
        try {
            const result = await Nofitication.deleteBulkNotification(params)
            if (result?.success) {
                getListNotification(filter)
                return message.success('Xoá thông báo thành công', 1)
            }
            return message.error('Xoá thông báo thất bại', 1)
        } catch (e) {
            return message.error('Xoá thông báo thất bại', 1)
        }
    }
    const readBulkNotification = async (params) => {
        try {
            const result = await Nofitication.readBulkNotification(params)
            if (result?.success) {
                await getListNotification(filter)
                message.success('Đánh dấu đã đọc thông báo thành công', 1)

            } else {
                message.error('Đánh dấu đọc thông báo thất bại', 1)
            }
        } catch (e) {
            message.error('Đánh dấu đọc thông báo thất bại', 1)
        }
    }


    const notificationList = (
        <div className="nav-dropdown nav-notification">
            <div className="nav-notification-header d-flex justify-content-between align-items-center">
                <h4 className="mb-0 cursor-pointer"
                    onMouseEnter={() => setOpen(true)}
                    ref={ref1}
                >Thông báo <InfoCircleOutlined/>
                </h4>

                <Tour
                    rootClassName="tour-notify"
                    open={open}
                    onClose={() => setOpen(false)}
                    mask={false}
                    type="primary" steps={steps}
                    indicatorsRender={(current, total) => (<span> {current + 1} / {total} </span>)}
                />

                <Dropdown trigger={['click']} overlay={
                    <Menu>
                        <Menu.Item onClick={() => readBulkNotification()}>
                            <CheckCircleTwoTone/> Đánh dấu đã đọc
                        </Menu.Item>
                        <Menu.Item onClick={() => deleteBulkNotification()}>
                            <DeleteOutlined/> Xóa tin đã đọc
                        </Menu.Item>
                        <Menu.Item onClick={() => deleteBulkNotification({})}>
                            <DeleteOutlined/> Xóa tất cả
                        </Menu.Item>
                    </Menu>
                }>
                    <a onClick={(e) => e.preventDefault()}>
                        <MoreOutlined/>
                    </a>
                </Dropdown>
            </div>
            <div className="nav-notification-body">
                {data ?
                    (<List
                        size="small"
                        itemLayout="horizontal"
                        dataSource={data?.notifications}
                        renderItem={item => (
                            <List.Item className="list-clickable"
                                       style={{background: item.is_read ? '#ffffff' : 'rgba(209,218,245,0.32)'}}>
                                <Flex alignItems="center">
                                    <div className="pr-3" onClick={() => {
                                        readNotification(item)
                                    }}>
                                        {item?.metadata.icon ?
                                            <img style={{width: 35, height: 35}} src={`${item.metadata.icon}`}/> :
                                            <Avatar className={`ant-avatar-${item.type}`} icon={getIcon(item.icon)}/>}
                                    </div>
                                    <div className="mr-3" onClick={() => {
                                        readNotification(item)
                                    }}>
                                        <div className="font-weight-bold text-dark">{_.truncate(item.title, {
                                            'length': 50,
                                            'separator': /,? +/
                                        })}</div>
                                        <div className="text-gray-light">{_.truncate(item.message, {
                                            'length': 60,
                                            'separator': /,? +/

                                        })}</div>
                                        <small
                                            className="ml-auto">{dayjs(item.created_at).format('hh:mm DD/MM/YYYY')}</small>
                                    </div>
                                    <div>
                                        <Tooltip onClick={() => {
                                            handelDeleteNotify(item.id)
                                        }} title="Xoá bỏ" color="red">
                                            <DeleteOutlined style={{color: 'red'}}/>
                                        </Tooltip>
                                    </div>
                                </Flex>
                            </List.Item>
                        )}
                    />)
                    : (<div className="empty-notification">
                        <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty"/>
                        <p className="mt-3">Không có thông báo mới</p>
                    </div>)
                }
            </div>
            {
                data?.notifications?.length ?
                    <div className="nav-notification-footer">
                        <a className="d-block" onClick={LoadMore}>Xem thêm</a>
                    </div>
                    :
                    null
            }
        </div>
    );

    return (
        <Dropdown
            placement="bottomRight"
            overlay={notificationList}
            onOpenChange={handleVisibleChange}
            open={visible}
            trigger={['click']}
        >
            <div className="ml-3 cursor-pointer">
                <Badge count={data?.countUnRead}>
                    <BellOutlined className="nav-icon mx-auto" type="bell"/>
                </Badge>
            </div>
        </Dropdown>
    )
}


export default NavNotification;

/* eslint-disable */
import React, {lazy, Suspense} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Loading from 'components/shared-components/Loading';

const Login = lazy(() => import(`./authentication/login-1`));
const LoginRedirect = lazy(() => import(`./authentication/login-redirect`));

export const AppViews = () => {
    return (
        <Suspense fallback={<Loading cover="page"/>}>
            <Routes>
                <Route path="login" element={<Login/>}/>
                <Route path=":providerName/callback" element={<LoginRedirect/>}/>
                <Route index element={<Navigate to="login"/>}/>
            </Routes>
        </Suspense>
    )
}

export default AppViews;

import { GET_LIST_CONFIG } from '../constants/SystemConfiguration';

export const initState = {
  list_config: undefined,
};

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_LIST_CONFIG:
      return {
        ...state,
        list_config: action.data,
      };
    default:
      return state;
  }
};

export const SIGNIN = 'SIGNIN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const AUTH_TOKEN = 'auth_token'
export const USER_INFO = 'user_info'
export const USER_PERMISSIONS = 'user_permissions'
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE'
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = 'SIGNIN_WITH_GOOGLE_AUTHENTICATED'
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = 'SIGNIN_WITH_FACEBOOK_AUTHENTICATED'
export const SIGNIN_WITH_MICROSOFT = "SIGNIN_WITH_MICROSOFT"
export const TEAMS_USER_CREDENTIAL = "TEAMS_USER_CREDENTIAL"


const createActionTypeSet = (action) => {
    return {
        REQUEST: action + '_REQUEST',
        SUCCESS: action + '_SUCCESS',
        FAILURE: action + '_FAILURE',
    }
}

export const LOGIN_GETWAY = createActionTypeSet('LOGIN_GETWAY')
export const RESET_AUTH = createActionTypeSet('RESET_AUTH')


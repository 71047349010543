export const STATUS_CODE_WORK = {
    QUA_HAN: 'QUA_HAN',
    HOAN_THANH: 'HOAN_THANH',
    CHUA_BAT_DAU: 'CHUA_BAT_DAU',
    CHO_XAC_NHAN: 'CHO_XAC_NHAN',
};

export const MESS_ERROR = 'Dữ liệu bị trống hoặc có thể tồn tại một lỗi không mong muốn';

export const FORMAT_DATE_TIME_START_DAY = 'YYYY-MM-01 00:00:00';

export const FORMAT_DATE_TIME_END_DAY = 'YYYY-MM-DD 23:59:59';

export const STATUS_WORK = {
    [STATUS_CODE_WORK['QUA_HAN']]: 'Quá hạn',
    [STATUS_CODE_WORK['HOAN_THANH']]: 'Hoàn thành',
    [STATUS_CODE_WORK['CHUA_BAT_DAU']]: 'Chưa bắt đầu',
    [STATUS_CODE_WORK['CHO_XAC_NHAN']]: 'Chờ xác nhận',
};

export const TYPE_SALARY = [
    {value: 1, label: 'Sổ lương', path: 'salary-summaries'},
    // {value: 2, label: 'Chấm công', path: 'timekeeping'},
    // {value: 3, label: 'Làm ngoài giờ', path: 'overtime'},
    // {value: 4, label: 'Tạm ứng', path: 'salary-advance'},

    // { value: 2, label: '', path: '' },
    // { value: 3, label: '', path: '' },
    // { value: 4, label: '', path: '' },
];

export const TYPE_COMPLEXITY = [
    {
        value: 'Medium',
        label: 'Bình thường',
    },
    {
        value: 'High',
        label: 'Khó',
    },
    {
        value: 'Extreme',
        label: 'Cực khó',
    },
];

export const TYPE_STATUS = [
    {
        value: 'StaffPending',
        label: 'Chờ xác nhận',
    },
    {
        value: 'ManagerPending',
        label: 'Chờ duyệt',
    },
    {
        value: 'NotStarted',
        label: 'Chưa bắt đầu',
    },
    {
        value: 'Canceled',
        label: 'Từ chối',
    },
    // {
    //     value: 'Overdue',
    //     label: 'Quá hạn',
    // },
    {
        value: 'InProgress',
        label: 'Đang thực hiện',
    },
    {
        value: 'Completed',
        label: 'Hoàn thành',
    },
    {
        value: 'Passed',
        label: 'Đã đánh giá',
    },
    // {
    //     value: 'PlannerSync',
    //     label: 'Chờ cập nhật',
    // },
];

export const TYPE_SCHEDULE = [
    {
        value: 1,
        label: 'Đúng hạn',
    },
    {
        value: 0,
        label: 'Trễ hạn',
    },
];

export const TYPE_QUALITY = [
    {
        value: 1,
        label: 'Đạt',
    },
    {
        value: 0,
        label: 'Không đạt',
    },
];
export const TYPE_PRIORITY = [
    {value: 'Low', label: 'Thấp'},
    {value: 'Medium', label: 'Bình thường'},
    {value: 'Important', label: 'Quan trọng'},
    {value: 'Urgent', label: 'Khẩn cấp'},
];

export const TYPE_SCOPE = [
    {value: 'In', label: 'Có '},
    {value: 'Out', label: 'Không'},
];

export const DAY_IN_MONTH = {
    0: 'CN',
    1: 'T2',
    2: 'T3',
    3: 'T4',
    4: 'T5',
    5: 'T6',
    6: 'T7',
};

export const TYPE_OVERTIME = [
    {id: 1, value: 'weekday'},
    {id: 2, value: 'weekend'},
    {id: 3, value: 'holiday'}
];

export const STATUS_OVERTIME = [
    {id: 1, value: 'request'},
    {id: 2, value: 'approve'},
    {id: 3, value: 'cancel'},
    {id: 4, value: 'evaluate'},
    {id: 5, value: 'success'},
    {id: 6, value: 'fail'}
]

export const TYPE_LEAVE = [
    {value: 1, label: 'Nghỉ không lương'},
    {value: 2, label: 'Nghỉ phép'},
    {value: 3, label: 'Nghỉ Lễ'},
    {value: 4, label: 'Nghỉ thai sản'},
    {value: 5, label: 'Nghỉ ốm'},
];

export const TAB_LIST_WORK = [
    {value: 'assignee', label: 'Công việc của tôi'},
    {value: 'involved', label: 'Công việc liên quan'},
    {value: 'manager', label: 'Việc tôi giao'},
];

export const LIST_WORK = {
    ASSIGNEE: 'assignee',
    INVOLVED: 'involved',
    MANAGER: 'manager',
};

export const TYPE_USER_LOGIN = {
    REPORTER: 'reporter',
    ASSIGNEE: 'assignee',
};

export const TYPE_COMPOSITION_NATURE = [
    {
        value: 'income',
        label: 'Thu nhập',
    },
    {
        value: 'deduct',
        label: 'Khấu trừ',
    },
    {
        value: 'different',
        label: 'Khác',
    },
];

export const TYPE_COMPOSITION_OPTION = [
    {
        value: 'formula',
        label: 'Công thức',
    },
    {
        value: 'number',
        label: 'Số',
    },
];

export const TYPE_SALARY_POLICY = [
    {
        value: 'income',
        label: 'Thu nhập',
    },
    {
        value: 'deduct',
        label: 'Khấu trừ',
    },
    {
        value: 'Salary',
        label: 'Lương',
    },
];

export const LIST_MONTH = [
    { value: 1, label: 'Tháng 1' },
    { value: 2, label: 'Tháng 2' },
    { value: 3, label: 'Tháng 3' },
    { value: 4, label: 'Tháng 4' },
    { value: 5, label: 'Tháng 5' },
    { value: 6, label: 'Tháng 6' },
    { value: 7, label: 'Tháng 7' },
    { value: 8, label: 'Tháng 8' },
    { value: 9, label: 'Tháng 9' },
    { value: 10, label: 'Tháng 10' },
    { value: 11, label: 'Tháng 11' },
    { value: 12, label: 'Tháng 12' },
]

export const LIST_DAY_IN_WEEK = [
    { value: 0, label: 'CN' },
    { value: 1, label: 'Thứ 2' },
    { value: 2, label: 'Thứ 3' },
    { value: 3, label: 'Thứ 4' },
    { value: 4, label: 'Thứ 5' },
    { value: 5, label: 'Thứ 6' },
    { value: 6, label: 'Thứ 7' },
]

export const LIST_DAY_ORDER = [
    { value: 1, label: 'Đầu tiên' },
    { value: 2, label: 'Thứ hai' },
    { value: 3, label: 'Thứ ba' },
    { value: 4, label: 'Thứ tư' },
]




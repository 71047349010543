import React from "react";
import {Layout} from 'antd';
import {connect} from 'react-redux';
import {NAV_TYPE_SIDE, SIDE_NAV_DARK, SIDE_NAV_WIDTH} from 'constants/ThemeConstant';
import {Scrollbars} from "react-custom-scrollbars-2";
import MenuContent from './MenuContent'
import {IS_TEAM} from "../../configs/AppConfig";
import {onMobileNavToggle, toggleCollapsedNav} from "../../redux/actions";

const {Sider} = Layout;

export const SideNav = (
    {
        navCollapsed, sideNavTheme, routeInfo, hideGroupTitle, localization = true, toggleCollapsedNav,
        onMobileNavToggle,
        isMobile,
        mobileNav
    }
) => {
    const props = {sideNavTheme, routeInfo, hideGroupTitle, localization}

    const onToggle = () => {
        if (!isMobile) {
            toggleCollapsedNav(!navCollapsed);
        } else {
            onMobileNavToggle(!mobileNav);
        }
    };

    return (
        <Sider
            className={`side-nav ${sideNavTheme === SIDE_NAV_DARK ? 'side-nav-dark' : 'ant-layout-sider-light'}`}
            style={IS_TEAM && {top: 0}}
            width={SIDE_NAV_WIDTH}
            collapsible
            collapsed={navCollapsed}
            onCollapse={onToggle}
        >
            <Scrollbars autoHide>
                <MenuContent
                    type={NAV_TYPE_SIDE}
                    {...props}
                />
            </Scrollbars>
        </Sider>
    )
}

const mapStateToProps = ({theme}) => {
    const {navCollapsed, sideNavTheme,mobileNav} = theme;
    return {navCollapsed, sideNavTheme,mobileNav}
};

export default connect(mapStateToProps, {
    toggleCollapsedNav,
    onMobileNavToggle,
})(SideNav);

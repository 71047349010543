import { GET_LIST_STATUS } from '../constants/Status';

export const initState = {
  list_status: undefined,
};

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_LIST_STATUS:
      return {
        ...state,
        list_status: action.data,
      };
    default:
      return state;
  }
};

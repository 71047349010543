const createActionTypeSet = (action) => {
    return {
        REQUEST: action + '_REQUEST',
        SUCCESS: action + '_SUCCESS',
        FAILURE: action + '_FAILURE',
    }
}

export const CHECK_COMPANY = createActionTypeSet('CHECK_COMPANY')
export const SETUP_COMPANY = createActionTypeSet('SETUP_COMPANY')


import React, {useRef} from 'react';
import {Button, Steps, theme} from 'antd';
import IntlMessage from "../../components/util-components/IntlMessage";
import WinrarStepOne from "./WinrarStepOne";
import WinrarComplete from "./WinrarComplete";
import WinrarStepInfo from "./WinrarStepInfo";
import {useDispatch, useSelector} from "react-redux";
import {handleSetupCompany} from "../../redux/actions/company";

const WinrarCompany = () => {
    const {token} = theme.useToken();
    const {currentStep, isCreatingTenant,isDisabledNext, isDisabledPrev} = useSelector((state) => state.company);
    const dispatch = useDispatch();
    const formRefStep1 = useRef(null);  // New line: Create a ref to hold the form instance

    const steps = [
        {
            title: 'team.winrar.step0',
            content: <WinrarStepInfo/>,
        },
        {
            title: 'team.winrar.step1',
            content: <WinrarStepOne formRef={formRefStep1} />,
        },
        {
            title: 'team.winrar.step4',
            content: <WinrarComplete/>,
        },
    ];

    const next = () => {
        if (currentStep === 0) {
            dispatch(handleSetupCompany.success({currentStep: currentStep + 1}))
        } else if (currentStep === 1) {
            formRefStep1.current.submit();
        }
    };

    const prev = () => {
        dispatch(handleSetupCompany.success({currentStep: currentStep - 1}))
    };
    const items = steps.map((item) => ({
        key: item.title,
        title: <IntlMessage id={item.title}/>,
    }));

    return (
        <>
            <Steps current={currentStep} items={items}/>
            <div style={{
                lineHeight: '260px',
                textAlign: 'center',
                color: token.colorTextTertiary,
                backgroundColor: token.colorFillAlter,
                borderRadius: token.borderRadiusLG,
                border: `1px dashed ${token.colorBorder}`,
                marginTop: 16,
            }}>{steps[currentStep].content}</div>
            <div
                style={{
                    marginTop: 24,
                }}
            >
                {currentStep > 0 && !isCreatingTenant  && (
                    <Button
                        disabled={isDisabledPrev}
                        style={{
                            margin: '0 8px',
                        }}
                        onClick={() => prev()}
                    >
                        <IntlMessage id="Previous"/>

                    </Button>
                )}

                {currentStep < steps.length - 1 && (
                    <Button
                        disabled={isDisabledNext}
                        type="primary" onClick={() => next()}>
                        <IntlMessage id="Next"/>
                    </Button>
                )}

            </div>
        </>
    );
};
export default WinrarCompany;

import fetch from 'auth/FetchInterceptor';

const SyncService = {
    synManual: (params ={}) => {
        return fetch({
            url: `/ms/sync-manual`,
            method: "get",
            params,
        });
    },
    syncTimeKeepingManual: (params ={}) => {
        return fetch({
            url: `/ms/syncTimeKeepingManual`,
            method: "get",
            params,
        });
    },

};

export default SyncService;


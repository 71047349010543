import React from 'react';
import {PlusSquareOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {FloatButton, Tooltip} from 'antd';
import {APP_PREFIX_PATH} from "../../../configs/AppConfig";
import {useNavigate} from "react-router-dom";
import SynCom from "../../../components/general/SyncCom";

const FloatAction = () => {

    const navigate = useNavigate();

    return (
        <>
            <FloatButton.Group
                shape="square"
                style={{
                    right: 24,
                }}
            >
                <Tooltip title="Hướng dẫn sử dụng">
                <FloatButton icon={<QuestionCircleOutlined />} />
                </Tooltip>
                <Tooltip title="Thêm mới công việc">
                    <FloatButton
                        icon={<PlusSquareOutlined />}
                        onClick={() => navigate(`${APP_PREFIX_PATH}/work/create-job`)}
                    />
                </Tooltip>

                <div className="text-center bg-white">
                    <SynCom/>
                </div>

                <FloatButton.BackTop visibilityHeight={0} />
            </FloatButton.Group>
        </>
    );
}
export default FloatAction;
import React, {lazy, Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Loading from "../../components/shared-components/Loading";

const Dashboards = lazy(() => import(`./dashboards`));
const Apps = lazy(() => import(`./apps`));
const Components = lazy(() => import(`./components`));
const Pages = lazy(() => import(`./pages`));
const Maps = lazy(() => import(`./maps`));
const Charts = lazy(() => import(`./charts`));
const Docs = lazy(() => import(`./docs`));
const DashboardsApp = lazy(() => import(`./dashboards_app/default`));
const Work = lazy(() => import(`./work`));
const Hrm = lazy(() => import(`./hrm`));
const Setting = lazy(() => import(`./setting`));
const Paycheck = lazy(() => import(`./paycheck`));
const SalaryStructure = lazy(() => import(`./hrm/payroll/salary-structure`));
const SalarySummaries = lazy(() => import(`./salary-summaries`));
const Report = lazy(() => import(`./report`));
const DemoForm = lazy(() => import(`./demo-form`));

export const AppViews = () => {
    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <Routes>
                <Route index element={<Navigate to="dashboards-app" />} />
                <Route path="dashboards-app/*" index element={<DashboardsApp/>}/>
                <Route path="dashboards/*" element={<Dashboards/>}/>
                <Route path="apps/*" element={<Apps/>}/>
                <Route path="components/*" element={<Components/>}/>
                <Route path="pages/*" element={<Pages/>}/>
                <Route path="maps/*" element={<Maps/>}/>
                <Route path="charts/*" element={<Charts/>}/>
                <Route path="docs/*" element={<Docs/>}/>
                <Route path="work/*" element={<Work/>}/>
                <Route path="hrm/*" element={<Hrm/>}/>
                <Route path="settings/*" element={<Setting/>}/>
                <Route path="paycheck/*" element={<Paycheck/>}/>
                <Route path="salary-structure/*" element={<SalaryStructure/>}/>
                <Route path="salary-summaries/*" element={<SalarySummaries/>}/>
                <Route path="report/*" element={<Report/>}/>
                <Route path="demo-form/*" element={<DemoForm/>}/>
            </Routes>
        </Suspense>
    );
};

export default React.memo(AppViews);

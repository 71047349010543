import fetch from 'auth/FetchInterceptor';

const Nofitication = {};
const paramDefault = {
    _sort: 'created_at:DESC',
}

Nofitication.getListNotification = function (params) {
    params = {...paramDefault, ...params};
    return fetch({
        url: '/notifications',
        method: 'get',
        params,
    });
};

Nofitication.countUnReadNotification = function (params) {
    params = {...paramDefault, ...params};
    return fetch({
        url: '/notifications/count',
        method: 'get',
        params,
    });
};

Nofitication.updateNotification = function (id, data) {
    return fetch({
        url: `/notifications/${id}`,
        method: 'put',
        data
    });
};

Nofitication.deleteNotification = function (id) {
    return fetch({
        url: `/notifications/${id}`,
        method: 'delete'
    });
};
Nofitication.deleteBulkNotification = function (params = {is_read: true}) {
    return fetch({
        url: `/notifications/bulk`,
        method: 'post',
        params
    });
};
Nofitication.readBulkNotification = function (params = {}) {
    return fetch({
        url: `/notifications/read-bulk`,
        method: 'post',
        params
    });
};

export default Nofitication;

import {combineReducers} from 'redux';
import {reducer as form} from 'redux-form';
import Auth from './Auth';
import Theme from './Theme';
import {reducer as Users} from './Users';
import {reducer as Projects} from './Projects';
import {reducer as Buckets} from './Buckets';
import {reducer as Departments} from './Departments';
import {reducer as SystemConfiguration} from './SystemConfiguration';
import {reducer as Status} from './Status';
import {reducer as Scope} from './Scope';
import {reducer as Tasks} from './Tasks';
import {reducer as TaskDetail} from './TaskDetail';
import CompanyReducer from './CompanyReducer';

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    form,
    users: Users,
    projects: Projects,
    buckets: Buckets,
    departments: Departments,
    configs: SystemConfiguration,
    status: Status,
    scope: Scope,
    task: Tasks,
    taskDetail: TaskDetail,
    company: CompanyReducer,
});

export default reducers;

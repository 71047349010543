export const GET_LIST_TASK = 'GET_LIST_TASK';
export const FILTER_TASK = 'FILTER_TASK';
export const TAB_ACTIVE_TASK = 'TAB_ACTIVE_TASK';
export const WIDGET_TASK = 'WIDGET_TASK';
export const PROJECT_TASK = 'PROJECT_TASK';
export const CARD_ACTIVE_TASK = 'CARD_ACTIVE_TASK';
export const IS_ACTIVE_BOX = 'IS_ACTIVE_BOX';
export const FILTER_BOX = 'FILTER_BOX';
export const IS_KANBAN_TABLE = 'IS_KANBAN_TABLE';


const createActionTypeSet = (action) => {
    return {
        REQUEST: action + '_REQUEST',
        SUCCESS: action + '_SUCCESS',
        FAILURE: action + '_FAILURE',
    }
}

export const TASK_COUNT = createActionTypeSet('TASK_COUNT')


import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Grid, Layout} from 'antd';
import {useLocation} from "react-router-dom";
import {useThemeSwitcher} from 'react-css-theme-switcher';

import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import Loading from 'components/shared-components/Loading';
import MobileNav from 'components/layout-components/MobileNav';
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import Footer from 'components/layout-components/Footer';
import {AppViews} from "../../views/app-views";
import FloatAction from "../../views/app-views/components/FloatAction";

import navigationConfig from 'configs/NavigationConfig';
import {
    DIR_LTR,
    DIR_RTL,
    NAV_TYPE_SIDE,
    NAV_TYPE_TOP,
    SIDE_NAV_COLLAPSED_WIDTH,
    SIDE_NAV_WIDTH,
} from 'constants/ThemeConstant';
import utils from 'utils';
import {dispatchTaskCount} from "../../redux/actions";
import {IS_TEAM} from "../../configs/AppConfig";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { navCollapsed, navType, direction } = useSelector(state => state.theme);
    const screens = useBreakpoint();
    const isMobile = !screens.sm;
    const isNavSide = navType === NAV_TYPE_SIDE;
    const isNavTop = navType === NAV_TYPE_TOP;

    useEffect(() => {
        dispatch(dispatchTaskCount.request());
    }, [dispatch]);

    const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname);

    const getLayoutGutter = () => isNavTop || isMobile ? 0 : (navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH);

    const { status } = useThemeSwitcher();

    if (status === 'loading') {
        return <Loading cover="page" />;
    }

    const layoutDirectionStyles = {
        paddingLeft: direction === DIR_LTR ? getLayoutGutter() : 0,
        paddingRight: direction === DIR_RTL ? getLayoutGutter() : 0,
    };

    return (
        <Layout>
            {!IS_TEAM && <HeaderNav isMobile={isMobile} />}
            {isNavTop && !isMobile && <TopNav routeInfo={currentRouteInfo} />}
            <Layout className="app-container">
                {isNavSide && !isMobile && <SideNav routeInfo={currentRouteInfo} />}
                <Layout className="app-layout" style={layoutDirectionStyles}>
                    <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`} style={IS_TEAM ? { marginTop: 0 } : {}}>
                        <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
                        <Content>
                            <AppViews />
                        </Content>
                        <Footer />
                        {IS_TEAM && <FloatAction />}
                    </div>
                </Layout>
            </Layout>
            {isMobile && <MobileNav />}
        </Layout>
    );
};

export default React.memo(AppLayout);

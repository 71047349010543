import React from 'react';
import {Card, Input, Space, Tag} from "antd";
import {useSelector} from "react-redux";
import {InfoCircleOutlined} from "@ant-design/icons";

const InfoCompany = () => {
    const {data: company} = useSelector((state) => state.company);
    return (
        <Card
            className="text-center"
            title="Thông tin công ty "
        >
            <Space style={{ width: 500}} className="m-auto" direction="vertical">
                <Input addonBefore="Name" classNames="w-100" disabled defaultValue={company.tenantName} />
                <Input addonBefore="Code" disabled defaultValue={company.tenantCode} />
                <Input addonBefore="Phone" disabled defaultValue={company.phone} />
                {company.address &&
                    <Input addonBefore="Address" disabled defaultValue={company.address} />
                }
            </Space>
           <div>
               <Space className="my-3">
                   <Tag icon={<InfoCircleOutlined/>} color="#cd201f">
                       Your account not permissions to access to dashboard
                   </Tag>
               </Space>
           </div>
        </Card>
    );
};
export default InfoCompany;

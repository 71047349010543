import antdViVn from 'antd/es/locale/vi_VN';
import viMsg from "../locales/vi_VN.json";

const EnLang = {
  antd: antdViVn,
  locale: 'vi-VN',
  messages: {
    ...viMsg
  },
};
export default EnLang;

import {GET_LIST_SCOPE} from '../constants/Scope';

export const initState = {
  list_scope: undefined,
};

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_LIST_SCOPE:
      return {
        ...state,
        list_scope: action.data,
      };
    default:
      return state;
  }
};

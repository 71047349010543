import {USER_INFO} from "../redux/constants/Auth";
import {TYPE_USER_LOGIN} from "./constants";
import {message, notification} from "antd";
import {saveAs} from 'file-saver';
import _ from 'lodash';

class Utils {

    /**
     * Get first character from first & last sentences of a username
     * @param {String} name - Username
     * @return {String} 2 characters string
     */
    static getNameInitial(name) {
        let initials = name.match(/\b\w/g) || [];
        return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    }

    /**
     * Get current path related object from Navigation Tree
     * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
     * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
     * @return {Object} object that contained the path string
     */
    static getRouteInfo(navTree, path) {
        if (navTree.path === path) {
            return navTree;
        }
        let route;
        for (let p in navTree) {
            if (Object.prototype.hasOwnProperty.call(navTree, p) && typeof navTree[p] === 'object') {
                route = this.getRouteInfo(navTree[p], path);
                if (route) {
                    return route;
                }
            }
        }
        return route;
    }

    /**
     * Get accessible color contrast
     * @param {String} hex - Hex color code e.g '#3e82f7'
     * @return {String} 'dark' or 'light'
     */
    static getColorContrast(hex) {
        if (!hex) {
            return 'dark'
        }
        const threshold = 130;
        const hRed = hexToR(hex);
        const hGreen = hexToG(hex);
        const hBlue = hexToB(hex);

        function hexToR(h) {
            return parseInt((cutHex(h)).substring(0, 2), 16)
        }

        function hexToG(h) {
            return parseInt((cutHex(h)).substring(2, 4), 16)
        }

        function hexToB(h) {
            return parseInt((cutHex(h)).substring(4, 6), 16)
        }

        function cutHex(h) {
            return (h.charAt(0) === '#') ? h.substring(1, 7) : h
        }

        const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
        if (cBrightness > threshold) {
            return 'dark'
        } else {
            return 'light'
        }
    }

    /**
     * Darken or lighten a hex color
     * @param {String} color - Hex color code e.g '#3e82f7'
     * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
     * @return {String} Darken or lighten color
     */
    static shadeColor(color, percent) {
        let R = parseInt(color.substring(1, 3), 16);
        let G = parseInt(color.substring(3, 5), 16);
        let B = parseInt(color.substring(5, 7), 16);
        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);
        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;
        const RR = ((R.toString(16).length === 1) ? `0${R.toString(16)}` : R.toString(16));
        const GG = ((G.toString(16).length === 1) ? `0${G.toString(16)}` : G.toString(16));
        const BB = ((B.toString(16).length === 1) ? `0${B.toString(16)}` : B.toString(16));
        return `#${RR}${GG}${BB}`;
    }

    /**
     * Convert RGBA to HEX
     * @param {String} rgba - RGBA color code e.g 'rgba(197, 200, 198, .2)')'
     * @return {String} HEX color
     */
    static rgbaToHex(rgba) {
        const trim = str => (str.replace(/^\s+|\s+$/gm, ''))
        const inParts = rgba.substring(rgba.indexOf("(")).split(","),
            r = parseInt(trim(inParts[0].substring(1)), 10),
            g = parseInt(trim(inParts[1]), 10),
            b = parseInt(trim(inParts[2]), 10),
            a = parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);
        const outParts = [
            r.toString(16),
            g.toString(16),
            b.toString(16),
            Math.round(a * 255).toString(16).substring(0, 2)
        ];

        outParts.forEach(function (part, i) {
            if (part.length === 1) {
                outParts[i] = '0' + part;
            }
        })
        return (`#${outParts.join('')}`);
    }

    /**
     * Returns either a positive or negative
     * @param {Number} number - number value
     * @param {any} positive - value that return when positive
     * @param {any} negative - value that return when negative
     * @return {any} positive or negative value based on param
     */
    static getSignNum(number, positive, negative) {
        if (number > 0) {
            return positive
        }
        if (number < 0) {
            return negative
        }
        return null
    }

    /**
     * Returns either ascending or descending value
     * @param {Object} a - antd Table sorter param a
     * @param {Object} b - antd Table sorter param b
     * @param {String} key - object key for compare
     * @return {any} a value minus b value
     */
    static antdTableSorter(a, b, key) {
        if (typeof a[key] === 'number' && typeof b[key] === 'number') {
            return a[key] - b[key]
        }

        if (typeof a[key] === 'string' && typeof b[key] === 'string') {
            a = a[key].toLowerCase();
            b = b[key].toLowerCase();
            return a > b ? -1 : b > a ? 1 : 0;
        }
        return
    }

    /**
     * Filter array of object
     * @param {Array} list - array of objects that need to filter
     * @param {String} key - object key target
     * @param {any} value  - value that excluded from filter
     * @return {Array} a value minus b value
     */
    static filterArray(list, key, value) {
        let data = list
        if (list) {
            data = list.filter(item => item[key] === value)
        }
        return data
    }

    /**
     * Remove object from array by value
     * @param {Array} list - array of objects
     * @param {String} key - object key target
     * @param {any} value  - target value
     * @return {Array} Array that removed target object
     */
    static deleteArrayRow(list, key, value) {
        let data = list
        if (list) {
            data = list.filter(item => item[key] !== value)
        }
        return data
    }

    /**
     * Wild card search on all property of the object
     * @param {Number | String} input - any value to search
     * @param {Array} list - array for search
     * @return {Array} array of object contained keyword
     */
    static wildCardSearch(list, input) {
        const searchText = (item) => {
            for (let key in item) {
                if (item[key] == null) {
                    continue;
                }
                if (item[key].toString().toUpperCase().indexOf(input.toString().toUpperCase()) !== -1) {
                    return true;
                }
            }
        };
        list = list.filter(value => searchText(value));
        return list;
    }

    /**
     * Get Breakpoint
     * @param {Object} screens - Grid.useBreakpoint() from antd
     * @return {Array} array of breakpoint size
     */
    static getBreakPoint(screens) {
        let breakpoints = []
        for (const key in screens) {
            if (Object.prototype.hasOwnProperty.call(screens, key)) {
                const element = screens[key];
                if (element) {
                    breakpoints.push(key)
                }
            }
        }
        return breakpoints
    }

    /**
     *
     * @param params
     * @returns {{_limit: number, _start: number}|{_limit, _start: number}}
     */

    static getPaginationParams(params) {
        if (params) {
            return {
                _limit: params.pageSize,
                _start: (params.current * params.pageSize) - params.pageSize
            }
        }

        return {
            _limit: 10,
            _start: 0
        }
    }

    static getUserInfo() {
        const userLogin = JSON.parse(localStorage.getItem(USER_INFO));
        if (userLogin) {
            return userLogin;
        }

        return null;
    }

    static check3rdCreateTask(data) {
        const userLogin = this.getUserInfo();
        // 3rd create task || manager create task => staff review
        if (userLogin.msid !== data.createdBy
            && userLogin.msid === data.assignee
            && data.receiverStatus === 'staffPending') {
            return false;
        }

        // staff create task => manager review
        if (userLogin.msid !== data.createdBy
            && userLogin.msid === data.reportRecipient
            && data.receiverStatus === 'managerPending') {
            return false;
        }

        return true;
    }

    static checkStatus3rd(data) {
        if (data.createdBy !== data.assignee && data.createdBy !== data.reportRecipient) {
            return true;
        }

        return false;
    }

    static checkTaskOverdue(data) {
        // if (data.endDate < dayjs().format('YYYY-MM-DD')
        //     && (data.status === 'NotStarted' || data.status === 'InProgress')) {
        //     return true;
        // }
        return data.isOverdue;

    }

    static checkUserLogin(data) {
        const userLogin = this.getUserInfo();
        if (userLogin.msid === data.reportRecipient) {
            return TYPE_USER_LOGIN.REPORTER;
        } else if (userLogin.msid === data.assignee) {
            return TYPE_USER_LOGIN.ASSIGNEE;
        }
    }

    static checkCreateTaskOnPlanner(data) {
        const userLogin = this.getUserInfo();
        if (data.userAssign === userLogin?.id) {
            return !data.userReport || Boolean(data.createdBy === userLogin?.msid && Boolean(!data.timeWork || !data.scope || !data.endDate));

        }
        return data.userReport === userLogin?.id &&
            Boolean(!data.timeWork || !data.scope || !data.endDate);

    }

    /**
     *
     * @constructor
     */

    static convertStringToSlug(title) {
        //Đổi chữ hoa thành chữ thường
        let slug;
        slug = title.toUpperCase();

        //Đổi ký tự có dấu thành không dấu
        slug = slug.replace(/[áàảạãăắằẳẵặâấầẩẫậ]/gi, 'A');
        slug = slug.replace(/[éèẻẽẹêếềểễệ]/gi, 'E');
        slug = slug.replace(/[iíìỉĩị]/gi, 'I');
        slug = slug.replace(/[óòỏõọôốồổỗộơớờởỡợ]/gi, 'O');
        slug = slug.replace(/[úùủũụưứừửữự]/gi, 'U');
        slug = slug.replace(/[ýỳỷỹỵ]/gi, 'Y');
        slug = slug.replace(/đ/gi, 'D');
        //Xóa các ký tự đặt biệt
        slug = slug.replace(/[`~!@#|$%^&*()+=,./?><'":;_]/gi, '');
        //Đổi khoảng trắng thành ký tự gạch ngang
        slug = slug.replace(/ /gi, "_");
        //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
        //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
        slug = slug.replace(/-----/gi, '_');
        slug = slug.replace(/----/gi, '_');
        slug = slug.replace(/---/gi, '_');
        slug = slug.replace(/--/gi, '_');
        //Xóa các ký tự gạch ngang ở đầu và cuối
        slug = '@' + slug + '@';
        slug = slug.replace(/@-|-@|@/gi, '');
        return slug;
    }

    static openNotificationWithIcon(type, notice) {
        notification[type]({
            message: notice,
        });
    }

    static getClassPriorityLevel = (level) => {
        switch (level) {
            case 'Completed':
                return 'text-success';
            case 'Passed':
                return 'text-success';
            case 'NotStarted':
                return 'text-info';
            case 'Failed':
                return 'text-danger';
            case 'InProgress':
                return 'text-info';
            case 'Canceled':
                return 'text-danger';
            case 'managerPending':
                return 'text-info';
            case 'StaffPending':
                return 'text-info';
            default:
                return '';
        }
    };

    static getClassStatus = (level) => {
        switch (level) {
            case '1':
                return 'text-danger';
            case '2':
                return 'text-warning';
            case '3':
                return 'text-success';
            case '4':
                return '';
            default:
                return '';
        }
    };
    static getLabelQuanlity = (level) => {
        switch (level) {
            case false:
                return 'Không đạt';
            case true:
                return 'Đạt';
            default:
                return '---';
        }
    };

    static getClassQuanlity = (level) => {
        switch (level) {
            case '1':
                return 'text-warning';
            case '2':
                return 'text-success';
            default:
                return '';
        }
    };

    static getLabelProcess = (level) => {
        switch (level) {
            case false:
                return 'Không đạt';
            case true:
                return 'Đúng hạn';
            default:
                return '---';
        }
    };

    static getClassProcess = (level) => {
        switch (level) {
            case '1':
                return 'text-warning';
            case '2':
                return 'text-success';
            default:
                return '';
        }
    };

    static convertStatus = (status) => {
        let statusAfterConvert;
        switch (status) {
            case 'request':
                statusAfterConvert = 'Đang yêu cầu';
                break;
            case 'approve':
                statusAfterConvert = 'Chấp thuận';
                break;
            case 'cancel':
                statusAfterConvert = 'Hủy bỏ';
                break;
            case 'evaluate':
                statusAfterConvert = 'Thúc giục';
                break;
            case 'success':
                statusAfterConvert = 'Thành công';
                break;
            case 'fail':
                statusAfterConvert = 'Thất bại';
                break;
            default:
                break;
        }
        return statusAfterConvert;
    }

    static convertType = (type) => {
        let typeAfterConvert;
        switch (type) {
            case 'weekday':
                typeAfterConvert = 'Ngày thường';
                break;
            case 'weekend':
                typeAfterConvert = 'Cuối tuần';
                break;
            case 'holiday':
                typeAfterConvert = 'Ngày lễ';
                break;
            default:
                break;
        }
        return typeAfterConvert;
    }

    static downloadBySaveFile = (url) => {
        const exts = url.split('/')
        const filename = exts[exts.length - 1]
        saveAs(url, filename)
    }

    static validateFileExtensionUpload(extension) {
        if (!_.includes(['csv', 'xlsx', 'xls'], extension)) {
            message.warn({content: 'File tải lên phải là file excel', duration: 5})
            return false;
        }
    }
}


export default Utils;

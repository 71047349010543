import fetch from 'auth/FetchInterceptor';

const WorkService = {};

const paramDefault = {
  _sort: 'created_at:DESC',
}

WorkService.getTasksList = function (params) {
  params = {...paramDefault, ...params};
  return fetch({
    url: '/tasks/assignee',
    method: 'get',
    params,
  });
};

WorkService.calculateApi = function (params) {
  params = {...paramDefault, ...params};
  const response =  fetch({
    url: 'salary/api-month',
    method: 'get',
    params
  })
  return response;
}

WorkService.getTasksListCount = function (params) {
  params = {...paramDefault, ...params};
  // console.log('overdue_work', params)

  return fetch({
    url: '/tasks/assignee/count',
    method: 'get',
    params,
  });
};

WorkService.getTasksManager = function (params) {
  params = {...paramDefault, ...params};

  return fetch({
    url: '/tasks/manager',
    method: 'get',
    params,
  });
}

WorkService.getTasksTrash = function (params) {
  params = {_sort: 'deletedAt:DESC', ...params};
  return fetch({
    url: '/tasks/trash',
    method: 'get',
    params,
  });
};

WorkService.restoreTasks = function (id, data) {
  return fetch({
    url: `/tasks/restore-deleted/${id}`,
    method: 'post',
    data: data,
  });
};

WorkService.getTasksManagerCount = function (params) {
  params = {...paramDefault, ...params};

  return fetch({
    url: '/tasks/manager/count',
    method: 'get',
    params,
  });
};

WorkService.getTasksInvolved = function (params) {
  params = {...paramDefault, ...params};

  return fetch({
    url: '/tasks/involved',
    method: 'get',
    params,
  });
};

WorkService.getTasksInvolvedCount = function (params) {
  params = {...paramDefault, ...params};
  // console.log('count involved', params);

  return fetch({
    url: '/tasks/involved/count',
    method: 'get',
    params,
  });
};

WorkService.getDetailTask = function (id) {
  // params = {...paramDefault, ...params};

  return fetch({
    url: `tasks/detail/${id}`,
    method: 'get',
    // params,
  });
};

WorkService.createTasks = function (data) {
  return fetch({
    url: '/tasks',
    method: 'post',
    data: data,
  });
};

WorkService.updateTasks = function (id, data) {
  return fetch({
    url: `/tasks/${id}`,
    method: 'put',
    data: data,
  });
};

WorkService.deleteTasks = function (id, data) {
  return fetch({
    url: `tasks/soft-delete/${id}`,
    method: 'put',
    data: data,
  });
};

WorkService.reviewTasks = function (id, data) {
  return fetch({
    url: `/tasks/review/${id}`,
    method: 'put',
    data: data,
  });
};

WorkService.assessmentTasks = function (id, data) {
  return fetch({
    url: `/tasks/assessment/${id}`,
    method: 'put',
    data: data,
  });
};

WorkService.changeStatusTasks = function (id, data) {
  return fetch({
    url: `/tasks/status/${id}`,
    method: 'put',
    data: data,
  });
};

WorkService.getCountTaskWidget = function (params) {
  return fetch({
    url: '/tasks/count-task-widget',
    method: 'get',
    params
  });
}

WorkService.getListAssigneeByManager = function (params) {
  return fetch({
    url: '/tasks/get-assignee-by-report-recipient',
    method: 'get',
    params:{
      ...params,
      _sort: 'created_at:DESC',
    },
  });
}

export default WorkService;

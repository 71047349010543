import * as actionType from '../constants/Tasks';
import {
    CARD_ACTIVE_TASK,
    FILTER_BOX,
    FILTER_TASK,
    GET_LIST_TASK,
    IS_ACTIVE_BOX,
    IS_KANBAN_TABLE,
    PROJECT_TASK,
    TAB_ACTIVE_TASK,
    WIDGET_TASK
} from '../constants/Tasks';
import {LIST_WORK} from "../../utils/constants";


export const initState = {
    list_task: [],
    init_filters: {},
    tab_active: LIST_WORK.ASSIGNEE,
    widget_task: [],
    project_task: [],
    card_active: null,
    active_box: false,
    filters_box: {},
    isKanban: true,
    pendingTasks: undefined,
    evaluatingTasks: undefined
};

export const reducer = (state = initState, action) => {
    switch (action.type) {
        case GET_LIST_TASK:
            return {
                ...state,
                list_task: action.data,
            };
        case actionType.TASK_COUNT.SUCCESS:
            return {
                ...state,
                pendingTasks: action.payload.pendingTasks !== undefined ? action.payload.pendingTasks : state.pendingTasks,
                evaluatingTasks: action.payload.evaluatingTasks !== undefined ? action.payload.evaluatingTasks : state.evaluatingTasks,
            }
        case FILTER_TASK:
            return {
                ...state,
                init_filters: action.data,
            };
        case TAB_ACTIVE_TASK:
            return {
                ...state,
                tab_active: action.data,
            };
        case WIDGET_TASK:
            return {
                ...state,
                widget_task: action.data,
            };
        case PROJECT_TASK:
            return {
                ...state,
                project_task: action.data,
            };
        case CARD_ACTIVE_TASK:
            return {
                ...state,
                card_active: action.data,
            };
        case IS_ACTIVE_BOX:
            return {
                ...state,
                active_box: action.data,
            };
        case FILTER_BOX:
            return {
                ...state,
                filters_box: action.data,
            };
        case IS_KANBAN_TABLE:
            return {
                ...state,
                isKanban: action.isKanban
            }
        default:
            return state;
    }
};

import {all, call, delay, fork, put, select, takeEvery} from 'redux-saga/effects';
import {AUTH_TOKEN, LOGIN_GETWAY, SIGNIN_WITH_MICROSOFT, SIGNOUT, USER_INFO,} from '../constants/Auth';
import {authenticated, handleLoginGetWay, showAuthMessage, signIn, signOutSuccess} from "../actions/Auth";

import LoginService from 'services/LoginService'
import {HRM_ROLE_TAG} from "../../constants/SystemConfig";
import {CHECK_COMPANY} from "../constants/Company";
import renewTokenGraph from "../../utils/helpers";

const TOKEN_RENEWAL_BUFFER = 360000; // 5 m

function* autoRenewToken(expiresIn, teamsUserCredential) {
    try {
        const renewAt = Date.now() + (expiresIn * 1000) - TOKEN_RENEWAL_BUFFER;
        yield delay(renewAt - Date.now());
        const {expiresIn: newExpiresIn} = yield call(renewAndGetToken, teamsUserCredential);
        console.log("start autoRenewToken");
        yield call(autoRenewToken, newExpiresIn, teamsUserCredential);
    } catch (error) {
        console.error("Error in autoRenewToken:", error);
    }
}

function* renewAndGetToken(teamsUserCredential) {
    const graphToken = yield call(renewTokenGraph, teamsUserCredential);
    const tokenGetWay = yield call(LoginService.loginGetWay, graphToken);
   if (tokenGetWay.access_token){
       yield put(handleLoginGetWay.success({
           graphToken,
           tokenGetWay,
           token: tokenGetWay.access_token,
       }));
       return tokenGetWay;
   }
   throw Error ("renewAndGetToken loginGetWay error ")
}

export function* signInWithGetWay() {
    yield takeEvery(LOGIN_GETWAY.REQUEST, function* ({payload: {renewToken,teamsUserCredential}}) {
        try {
            const tokenGetWay = yield select(state => state.auth.tokenGetWay);
            if (!renewToken && tokenGetWay?.access_token && tokenGetWay?.expiresOnTimestamp && Date.now() < tokenGetWay.expiresOnTimestamp) {
                yield put({type: CHECK_COMPANY.REQUEST});
                yield put(handleLoginGetWay.success({loading: false}))
                return;
            }
            console.log("renewAndGetToken",{renewToken})
            const {expiresIn} = yield call(renewAndGetToken, teamsUserCredential);
            yield put({type: CHECK_COMPANY.REQUEST});
            yield put(handleLoginGetWay.success({loading: false}))
            yield fork(autoRenewToken, expiresIn, teamsUserCredential);

        } catch (error) {
            console.error("Error in signInWithGetWay:", error);
            yield put(handleLoginGetWay.failure());
        }
    });
}


export function* signInWithMicrosoft() {
    yield takeEvery(SIGNIN_WITH_MICROSOFT, function* ({payload}) {
        const {urlCallback} = payload;

        try {
            const {jwt: authToken, user} = yield LoginService.handleCallbackLogin(urlCallback);
            const departmentManagers = yield LoginService.departmentsByManager({managers: user.id},`Bearer ${authToken}`);
            if (departmentManagers && user.role.type !== HRM_ROLE_TAG) {
                user.role.type = "manager";
                user.department_managers = departmentManagers;
            }
            yield put(signIn(user));
            yield put(authenticated(authToken));
        } catch (err) {
            console.error("signInWithMicrosoft",err)
            yield put(handleLoginGetWay.failure());
        }
    });
}

export function* signOut() {
    yield takeEvery(SIGNOUT, function* () {
        try {
            localStorage.removeItem(AUTH_TOKEN);
            localStorage.removeItem(USER_INFO);
            yield put(signOutSuccess());

        } catch (err) {
            yield put(showAuthMessage(err));
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(signInWithGetWay),
        fork(signInWithMicrosoft),
        fork(signOut),
    ]);
}
